<template>
  <!-- :is 可根據傳入參數的不同，而去切換不同的元件 -->

  <component :is="resolveLayout">
    <router-view></router-view>
    <LoadingBar :load="showLoadingPage"></LoadingBar>
    <AlertBox :type="alertType" :text="alertText" :show="showAlert"></AlertBox>
    <AlertDialog></AlertDialog>
    <ConfirmLeaveDialog></ConfirmLeaveDialog>
    <v-overlay :value="showOverlay" :z-index="8"></v-overlay>

  </component>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useRouter } from '@/utils'
import LayoutBlank from '@/views/layouts/Blank.vue'
import LayoutContent from '@/views/layouts/Content.vue'
import LoadingBar from '@/components/LoadingBar'
import AlertBox from '@/components/AlertBox'
import AlertDialog from '@/components/AlertDialog.vue'
import ConfirmLeaveDialog from '@/components/ConfirmLeaveDialog.vue'

export default {
  components: {
    LayoutBlank,
    LayoutContent,
    LoadingBar,
    AlertBox,
    AlertDialog,
    ConfirmLeaveDialog
  },
  setup() {
    const { route } = useRouter()

    const resolveLayout = computed(() => {
      // https://vuejs.org/v2/guide/computed.html#Computed-Properties
      // Handles initial route
      if (route.value.name === null) return null

      if (route.value.meta.layout === 'blank') return 'layout-blank'

      return 'layout-content'
    })

    return {
      resolveLayout,
    }
  },
  computed: {
    showLoadingPage() {
      return this.$store.getters.showLoadingPage
    },
    alertType() {
      return this.$store.getters.alertMessage.type
    },
    alertText() {
      return this.$store.getters.alertMessage.message
    },
    showAlert() {
      return this.$store.getters.alertMessage.show
    },
    showOverlay() {
      return this.$store.getters.showOverlay
    },
  },

}
</script>
