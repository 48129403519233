/**
 * user_type：
 * developer
 * manager 管理員
 * admin 行政
 * tutor 導師
 */

export const cannotAccessList = {
  'PayTuitionList': ['tutor'],
  'PayTuitionPayment': ['tutor'],
  'ProductList': ['tutor'],
  'ProductDetails': ['tutor'],
  'CreateProduct': ['tutor'],
  'ProductCategoryList': ['tutor'],
  'CreateProductCategory': ['tutor'],
  'TutorList': ['tutor'],
  'TutorDetails': ['tutor'],
  'CreateTutor': ['tutor'],
}

export const defaultPage = {
  developer: '/pay-tuition',
  manager: '/pay-tuition',
  admin: '/pay-tuition',
  tutor: '/pay-tuition'
}
