import { defaultPage } from "@/assets/cannotAccessList";

const SET_DIALOG = 'SET_DIALOG';
const SHOW_DIALOG = 'SHOW_DIALOG';

export default {
   state: {
      title: "",
      message: "",
      isError: false,
      showDialog: false,
      returnLink: null,
      userType: '',
   },
   actions: {
      setDialogMessage: ({ commit }, { title, message, isError, returnLink, userType }) => {
         let _title = '';
         let msg = '';
         let error = false;
         let _link = null;

         if (message === 'noPermission') {
            if (userType !== undefined && userType !== null && userType !== '' && defaultPage[userType]) {
               _link = { path: defaultPage[userType] };
            } else {
               _link = { name: 'login' };
            }
         } else {
            _link = returnLink;
         }

         switch (message) {
            case 'noData':
               _title = '找不到資料';
               msg = '找不到資料，請確保網址正確';
               error = true;
               break;
            case 'noPermission':
               _title = '沒有權限';
               msg = '你沒有權限進行此動作';
               error = true;
               break;
            default:
               _title = title;
               msg = message;
               error = isError;
               break;
         }
         commit(SET_DIALOG, { 
            title: _title, 
            message: msg, 
            isError: error, 
            returnLink: _link 
         });
      },

      setShowDialog: ({ commit }, value) => {
         commit(SHOW_DIALOG, value);
      }
   },
   mutations: {
      [SET_DIALOG]: (state, { title, message, isError, returnLink }) => {
         state.title = title;
         state.message = message;
         state.isError = isError;
         state.returnLink = returnLink;
      },
      [SHOW_DIALOG]: (state, value) => {
         state.showDialog = value;
      },
   },
}
