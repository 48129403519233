<template>
  <v-overlay v-show="load" z-index="999">
    <v-progress-circular color="primary" indeterminate size="64" />
  </v-overlay>
</template>

<script>
export default {
  name: 'LoadingBar',
  props: {
    load: {
      default: false,
      required: true,
      type: Boolean,
    },
  },
}
</script>
