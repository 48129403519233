require('./overrides.scss')

export default {
  theme: {
    themes: {
      light: {
        primary: '#1B718A',
        secondary: '#04243B',
        accent: '#FFB100',
        colorWhite: '#F2F4F4',
        colorBlack: '#3B3B3B',
        colorGrey: '#888888',
        colorLightGrey: '#A7A7A7',
        error: '#A32828',
        warning: '#e0ac08',
        info: '#4854e0',
        success: '#215003',
        borderColor: '#EAEAEA',
        red: '#F44336',
        whatsapp: '#55CD6B',
      },
      dark: {
        primary: '#1B718A',
        secondary: '#04243B',
        accent: '#FFB100',
        colorWhite: '#F2F4F4',
        colorBlack: '#3B3B3B',
        colorGrey: '#888888',
        colorLightGrey: '#A7A7A7',
        error: '#A32828',
        warning: '#e0ac08',
        info: '#4854e0',
        success: '#215003',
        borderColor: '#EAEAEA',
        red: '#F44336',
        whatsapp: '#55CD6B',
      },
    },
  },
}
