import Vuex, { Store } from 'vuex';
import Vue from 'vue';

// eslint-disable-next-line import/no-named-as-default
import VuexPersistence from 'vuex-persist';

import Common from './modules/Common';
import dialog from './modules/dialog';
import request from './modules/request';
import leaveDialog from './modules/leaveDialog';

Vue.use(Vuex)


export default new Store({
  namespaced: true,
  modules: {
    Common,
    dialog,
    request,
    leaveDialog
  },
  plugins: [
    new VuexPersistence({
      key: 'vuex',
      storage: window.localStorage,
      modules: [],
    }).plugin,
  ],
})
