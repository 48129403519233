var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{style:({ 'margin-left': _vm.$vuetify.breakpoint.mdAndDown ? '0' : '8px' }),attrs:{"flat":"","app":"","color":"white","height":"60"}},[(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.isDrawerOpen = !_vm.isDrawerOpen}}},[_c('v-icon',{staticClass:"fi fi-rr-arrow-alt-square-right",staticStyle:{"font-size":"22px"},attrs:{"color":"colorBlack"}})],1):_vm._e(),_c('v-spacer'),_c('v-menu',{attrs:{"flat":"","bottom":"","offset-y":"","nudge-bottom":"10","rounded":"lg","content-class":"elevation-0 elevation-custom","transition":"slide-y-transition","close-on-content-click":!_vm.disableCloseMenu},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"user-menu-open-button",attrs:{"depressed":"","text":""}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.userName)+_vm._s(_vm.$validate.DataValid(_vm.schoolName) ? ' | ' : '')+_vm._s(_vm.schoolName))]),_c('v-icon',{staticClass:"ml-8"},[_vm._v(" "+_vm._s(_vm.isUserMenuOpen ? _vm.icon.mdiChevronUp : _vm.icon.mdiChevronDown)+" ")])],1)]}}]),model:{value:(_vm.isUserMenuOpen),callback:function ($$v) {_vm.isUserMenuOpen=$$v},expression:"isUserMenuOpen"}},[_c('v-list',{staticClass:"px-2",attrs:{"dense":"","nav":""}},[_c('NavListItem',{staticClass:"user-menu-item logout-btn",attrs:{"icon":"fi fi-rr-replace","name":"切換分校"},on:{"btnClick":function () { _vm.schoolSelectDialogOpen = true }}})],1),_c('v-list',{staticClass:"px-2",attrs:{"dense":"","nav":""}},[_c('NavListItem',{staticClass:"user-menu-item logout-btn",attrs:{"icon":"fi fi-rr-sign-in-alt","name":"登出"},on:{"btnClick":function () {
          _vm.disableCloseMenu = false;
          this$1.logout();
        }}})],1)],1)],1),_c('NavDrawer',{attrs:{"drawerItemList":_vm.drawerItems,"isDrawerOpen":_vm.isDrawerOpen},on:{"update:isDrawerOpen":function($event){_vm.isDrawerOpen=$event},"update:is-drawer-open":function($event){_vm.isDrawerOpen=$event}}}),_c('DialogLayout',{attrs:{"openDialog":_vm.schoolSelectDialogOpen,"title":"選擇分校","enableScroll":"","btnAlign":"center","eager":""},on:{"close":function($event){return _vm.updateSelectedSchool($event)}}},[_c('v-radio-group',{model:{value:(_vm.tempSelectedSchoolId),callback:function ($$v) {_vm.tempSelectedSchoolId=$$v},expression:"tempSelectedSchoolId"}},_vm._l((_vm.tutorSchoolOptions),function(item){return _c('v-radio',{key:item.value,attrs:{"label":item.text,"value":item.value,"hide-details":""}})}),1)],1),_c('v-main',[_c('div',{class:_vm.mainContentClass},[_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('div',[[_vm._t("default")]],2)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }