<template>
    <DialogLayout 
        :openDialog="showDialog" 
        :isError="dialogIsError" 
        :title="dialogTitle" 
        :maxWidth="500"
        confirmBtnText="OK" 
        btnAlign="center" 
        @close="btnAction" 
        hideAction
    >
        <div class="d-flex flex-column align-center my-8">
            <div class="colorBlack--text subtitle-1" v-html="dialogMessage"></div>
        </div>
    </DialogLayout>
</template>
  
<script>
import { mapState, mapActions } from 'vuex';
import DialogLayout from '@/components/layout/DialogLayout.vue';

export default {
    name: 'AlertDialog',
    components: {
        DialogLayout
    },
    setup() {
        return {}
    },
    computed: {
        ...mapState({
            dialogTitle: state => state.dialog.title,
            dialogMessage: state => state.dialog.message,
            dialogIsError: state => state.dialog.isError,
            returnLink: state => state.dialog.returnLink,
        }),
        showDialog() {
            return this.$store.state.dialog.showDialog
        },
    },
    methods: {
        ...mapActions(['setShowDialog'], 'dialog'),
        async btnAction(action) {
            if (this.returnLink) {
                if (this.$validate.DataValid(this.returnLink.reload) && this.returnLink.reload === true) {
                    if (this.returnLink.reload === true) {
                        window.scrollTo(0, 0);
                        this.$router.go();
                    } else {
                        delete this.returnLink['reload'];
                        this.$router.replace(this.returnLink)
                    }

                } else {
                    if (this.returnLink.name === 'login') {
                        await this.removeUserCookies()
                    }
                    this.$router.replace(this.returnLink)
                }

            }

            this.setShowDialog(false)
        },
    },
}
</script>
