export default {
    install(Vue, option) {
        Vue.prototype.$formatter = new (function () {
            this.generateId = (id) => {
                return `#${this.pad(id, 5)}`;
            }

            this.generateCourseTitle = (course) => {
                return `${Vue.prototype.$grade[course.grade]} ${course.category_data ? course.category_data.name : ''}`
            }

            // ------ Date related
            /**
             * 
             * @param {String} d - YYYY-MM-DD | [YYYY-MM-DD]T[HH:MM:SS]
             * @returns String - weekday string
             */
            this.displayWeekday = (d) => {
                const w = this.getDay(d);
                return this.formatWeekday(w);
            }

            /**
            * 
            * @param {String} d - YYYY-MM-DD | [YYYY-MM-DD]T[HH:MM:SS]
            * @returns Number - weekday number
            */
            this.getDay = (d) => {
                if (d.length > 10) {
                    return new Date(`${d.split('T')[0]}T12:00:00+08:00`).getDay();
                }

                return new Date(`${d}T12:00:00+08:00`).getDay();
            }

            this.convertStrToDate = (d) => {
                if (d.length > 10) {
                    return new Date(`${d.split('T')[0]}T12:00:00+08:00`);
                }

                return new Date(`${d}T12:00:00+08:00`);
            }

            /**
             * 
             * @param {Number} year 
             * @param {Number} month - start from 0 to 6
             * @param {Number} date 
             */
            this.formatDateToDate = (year, month, date) => {
                return this.convertStrToDate(`${year}-${this.pad(month + 1)}-${this.pad(date)}`);
            }

            /**
            * 
            * @param {String} e - YYYY-MM-DD | [YYYY-MM-DD]T[HH:MM:SS]
            * @returns Number - date
            */
            this.dayFormat = (e) => {
                return parseInt(e.substring(8, 10), 10).toString()
            }

            /**
             * 
             * @param {*} d - new Date()
             * @returns String - YYYY-MM-DD
             */
            this.formatDate = d => {
                return `${d.getFullYear()}-${d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1}-${d.getDate() < 10 ? `0${d.getDate()}` : d.getDate()
                    }`
            }

            /**
             * 
             * @param {Number} w - weekday number
             * @returns String - weekday string
             */
            this.formatWeekday = w => {
                const weekday = ['日', '一', '二', '三', '四', '五', '六'];
                if (w > 6 && w < 0) {
                    return weekday[0];
                }

                return weekday[w];
            }

            // ----- Others
            /**
             * 
             * @param {Number | String} n - text/number will be padded
             * @param {Number} l - padded text length
             * @param {String} c - added string 
             * @param {Boolean} r - add text to left-hand-side
             * @param {Boolean} b 
             * @returns 
             */
            this.pad = (n, l, c, r, b) => {
                var s = n.toString(),
                    l = l || 2,
                    r = r !== false,
                    b = b !== false;
                if (s.length > 0) {
                    var p = "";
                    if (!isNaN(n) && s.charAt(0) == "-") {
                        p = "-";
                        s = s.substring(1);
                    }
                    while (s.length < l) {
                        if (r) s = (c || "0") + s;
                        else s += c || "0";
                    }
                    return b ? p + s : s;
                } else return "";
            }

            this.separator = (n, c, l) => {
                var l = l || 3;
                var r = new RegExp("\\B(?=(\\d{" + l + "})+(?!\\d))", "g");
                var p = n.toString().split(".");
                p[0] = p[0].replace(r, c || ",");
                return p.join(".");
            }

            /**
             * 
             * @param {Number} number 
             * @param {Number} decPlace - 取小數點後幾多個位
             * @returns Number
             */
            this.roundUp = (number, decPlace = 1) => {
                const multiplier = Math.pow(10, decPlace || 0);
                return Math.ceil(parseFloat(number) * multiplier) / multiplier;
            }

            this.formatMoney = (number) => {
                return this.roundUp(number);
            }

            // ----- Keydown Events
            this.number = event => {
                // [0-9]
                if (event.charCode >= 48 && event.charCode <= 57) {
                    return true
                }

                return event.preventDefault()
            }

            this.floating = event => {
                // [0-9][.][0-9]
                if ((event.charCode >= 48 && event.charCode <= 57) || event.charCode === 46) {
                    return true
                }

                return event.preventDefault()
            }

            this.numberWithNegative = event => {
                // [-][0-9]
                if ((event.charCode >= 48 && event.charCode <= 57) || event.charCode === 45) {
                    return true
                }

                return event.preventDefault()
            }

            this.floatingWithNegative = event => {
                // [-][0-9][.][0-9]
                if ((event.charCode >= 48 && event.charCode <= 57) || event.charCode === 46 || event
                    .charCode === 45) {
                    return true
                }

                return event.preventDefault()
            }

            this.alphaNumeric = event => {
                // [0-9A-Za-z]
                if (
                    (event.charCode >= 48 && event.charCode <= 57) ||
                    (event.charCode >= 65 && event.charCode <= 90) ||
                    (event.charCode >= 97 && event.charCode <= 122)
                ) {
                    return true
                }

                return event.preventDefault()
            }

            this.phone = event => {
                // +[0-9]
                if (event.charCode >= 48 && event.charCode <= 57 || event.charCode === 43) {
                    return true
                }

                return event.preventDefault()
            }

            this.engName = event => {
                // [a-zA-Z\s.]
                if (
                    (event.charCode >= 65 && event.charCode <= 90) ||
                    (event.charCode >= 97 && event.charCode <= 122) ||
                    event.charCode === 32 || event.charCode === 46
                ) {
                    return true
                }

                return event.preventDefault()
            }

            this.id = event => {
                // #[0-9]
                if (event.charCode >= 48 && event.charCode <= 57 || event.charCode === 35) {
                    return true
                }

                return event.preventDefault()
            }
        })()
    }
}