import axios from 'axios';

const UPDATING = 'UPDATING';
const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
const UPDATE_FAILED = 'UPDATE_FAILED';
const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';

export default {
   state: {
      isFetching: false,
      isSuccess: false,
      isFail: false,
      errorMessage: null
   },
   actions: {
      sendRequest: async ({ commit }, request) => {
         commit(UPDATING);
         try {
            const result = await axios({ ...request, timeout: 30000 });
            commit(UPDATE_SUCCESS);
            return result;
         } catch(err) {
            commit(UPDATE_FAILED);
            const { errorCode } = err.response.data;
            switch(errorCode) {
               case 403:
                  localStorage.removeItem('adminToken');
                  router.push('/login');
               default:
                  commit(SET_ERROR_MESSAGE, err.response.data);
                  return err.response;
            }
         }
      },

      setErrorMessage: ({commit}, message) => {
         commit(SET_ERROR_MESSAGE, message);
      }
   },
   mutations: {
      [UPDATING]: (state) => {
			state.isFetching = true;
			state.isSuccess = false;
			state.isFail = false;
      },
		[UPDATE_SUCCESS]: (state) => {
			state.isFetching = false;
			state.isSuccess = true;
         state.isFail = false;
      },
		[UPDATE_FAILED]: (state) => {
			state.isFetching = false;
			state.isSuccess = false;
			state.isFail = true;
      },
		[SET_ERROR_MESSAGE]: (state, errMessage) => {
			state.errorMessage = errMessage;
      },
   }
}