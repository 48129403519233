<template>
    <v-navigation-drawer floating :value="isDrawerOpen" class="nav-drawer" app disable-route-watcher
        :permanent="$vuetify.breakpoint.lgAndUp" :mini-variant="$vuetify.breakpoint.lgAndUp && miniDrawer"
        mini-variant-width="60" @input="updateDrawerStatus" :width="$vuetify.breakpoint.lgAndUp ? 256 : 320">
        <v-list class="nav-drawer-main" link nav dense :style="{
            'padding-left': $vuetify.breakpoint.mdAndDown ? '20px' : '8px',
            'padding-right': $vuetify.breakpoint.mdAndDown ? '20px' : '8px',
        }">
            <v-list-item class="nav-drawer-collapse-container" :class="{ 'justify-end':$vuetify.breakpoint.lgAndUp }">
                <!-- for desktop navigation drawer: miniDrawer toggle -->
                <template v-if="$vuetify.breakpoint.lgAndUp">
                    <v-btn v-if="!miniDrawer" icon @click.stop="toggleMiniDrawer(true)">
                        <v-icon color="colorBlack" class="fi fi-rr-angle-square-left drawer-collapse-icon"></v-icon>
                    </v-btn>
                </template>

                <!-- for mobile navigation drawer: close drawer -->
                <template v-else>
                    <v-btn icon @click="updateDrawerStatus(false)">
                        <v-icon color="colorBlack" class="fi fi-rr-cross-small"></v-icon>
                    </v-btn>
                </template>
            </v-list-item>

            <!-- for desktop navigation drawer: miniDrawer toggle -->
            <v-list-item v-if="miniDrawer" @click.stop="toggleMiniDrawer(false)" class="mini-drawer-expand-icon">
                <v-list-item-icon>
                    <v-icon class="fi fi-rr-angle-square-right"></v-icon>
                </v-list-item-icon>
                <v-list-item-content> </v-list-item-content>
            </v-list-item>

            <v-list-item-group color="primary" :class="{ 'mt-12': !miniDrawer }">
                <!-- for desktop and mobile navigation drawer -->
                <template v-if="!miniDrawer">
                    <template v-for="(listItem, index) in drawerItemList">
                        <NavListItem 
                            v-if="!listItem.isGroup && listItem.show" 
                            class="drawer-list-item" 
                            :activeClass="'drawer-list-item-active'" 
                            :key="index"
                            :name="listItem.text" 
                            :icon="listItem.icon"
                            :linkName="listItem.linkName"
                            @navClick="updateDrawerStatus(false)"
                        ></NavListItem>

                        <v-list-group 
                            v-else-if="listItem.isGroup && listItem.show && getOptionIsVisible(index)"
                            :key="`menu-item-${index}`" 
                            no-action 
                            :prepend-icon="listItem.icon"
                            active-class="drawer-dropdown-group-active" 
                            class="mb-2 drawer-dropdown-group"
                            :value="groupActiveState[index]"
                        >
                            <template v-slot:activator>
                                <v-list-item-title>{{ listItem.text }}</v-list-item-title>
                            </template>

                            <template v-for="(subItem, subIndex) in listItem.items">
                                <NavListItem v-if="subItem.show" class="drawer-dropdown-item"
                                    :activeClass="'drawer-list-item-active'" :key="subIndex" :name="subItem.text"
                                    :linkName="subItem.linkName" @navClick="updateDrawerStatus(false)"></NavListItem>
                            </template>
                        </v-list-group>
                    </template>
                </template>
                <!-- for desktop (mini) navigation drawer -->
                <template v-else>
                    <template v-for="(listItem, index) in drawerItemList">
                        <v-tooltip v-if="miniDrawer" right color="secondary" :key="`mini_${index}`">
                            <!-- tooltip -->
                            <template v-slot:activator="{ on: tooltip }">
                                <v-menu :disabled="!listItem.isGroup && listItem.show" min-width="170"
                                    content-class="mini-drawer-menu" right offset-x>
                                    <template v-slot:activator="{ on: menu, attrs }">
                                        <NavListItem 
                                            v-if="listItem.isGroup && listItem.show" 
                                            :class="{
                                                'mini-drawer-button': !listItem.isGroup && listItem.show,
                                                'mini-drawer-menu-button': listItem.isGroup && listItem.show,
                                                'mini-drawer-menu-button-link-active':
                                                    listItem.isGroup && listItem.show && groupActiveState[index],
                                            }" 
                                            :activeClass="listItem.isGroup && listItem.show 
                                                ? 'mini-drawer-menu-button-active' : 'mini-drawer-button-active'" 
                                            :name="listItem.text" 
                                            :linkName="listItem.linkName" 
                                            :icon="listItem.icon" 
                                            :activator="{ ...menu, ...tooltip }"
                                            :attrs="attrs"
                                            @navClick="updateDrawerStatus(false)"
                                        ></NavListItem>
                                    </template>
                                    <v-list link nav dense class="mini-drawer-list">
                                        <v-list-item-group color="primary">
                                            <template v-for="(subItem, subIndex) in listItem.items">
                                                <NavListItem :activeClass="'drawer-dropdown-item-active'"
                                                    class="drawer-dropdown-item" v-if="subItem.show"
                                                    :key="`mini_${subIndex}`" :name="subItem.text"
                                                    :linkName="subItem.linkName"
                                                    @navClick="updateDrawerStatus(false)"
                                                ></NavListItem>
                                            </template>
                                        </v-list-item-group>
                                    </v-list>
                                </v-menu>
                                <NavListItem v-show="!listItem.isGroup && listItem.show" class="mini-drawer-button"
                                    :activeClass="'mini-drawer-button-active'" :icon="listItem.icon" :name="listItem.text"
                                    :linkName="listItem.linkName" :activator="{ ...tooltip }" @navClick="updateDrawerStatus(false)"></NavListItem>
                            </template>
                            <span>{{ listItem.text }}</span>
                        </v-tooltip>
                    </template>
                </template>
            </v-list-item-group>

        </v-list>
    </v-navigation-drawer>
</template>

<script>

import NavListItem from '@/components/NavDrawer/NavListItem.vue';
export default {
    name: 'NavDrawer',
    components: {
        NavListItem
    },
    props: {
        isDrawerOpen: {
            type: Boolean,
            default: false
        },
        drawerItemList: {
            type: Array,
            default: () => []
        }

    },
    computed: {
        miniDrawer() {
            return this.$store.getters.miniDrawer;
        },
        isMobile() {
            return this.$vuetify.breakpoint.mdAndDown;
        },
        routerName() {
            return this.$route.name;
        }
    },
    data: () => ({
        groupActiveState: {},
        showMiniLink: false,
    }),
    watch: {
        isMobile(val) {
            if (val && this.miniDrawer) {
                this.toggleMiniDrawer(false);
            }
        },
        routerName(val) {
            this.activeDrawerGroup(val);
        }
    },
    methods: {
        updateDrawerStatus(val) {
            this.$emit('update:isDrawerOpen', val);
        },
        toggleMiniDrawer(state) {
            if (this.miniDrawer === state) {
                return;
            }

            this.$store.dispatch('setDrawer', state);
        },
        getOptionIsVisible(index) {
            // test the case if a item is a group but all of it sub items are invisible i.e. show = false
            // true = visible
            // false = invisible
            let result = false;

            const item = this.drawerItemList[index];
            if (this.$validate.DataValid(item)) {
                const filterVisibleSubItem = item.items.filter((obj) => {
                    return obj.show;
                });

                result = filterVisibleSubItem.length > 0;
            }

            return result;
        },
        activeDrawerGroup(routerName) {
            this.groupActiveState = {};

            for (let i = 0; i < this.drawerItemList.length; i++) {
                const item = this.drawerItemList[i];
                if (item.isGroup) {
                    const itemName = item.items.map((obj) => obj.linkName);
                    this.groupActiveState[i] = itemName.includes(routerName);
                }
            }
        },
    },
}
</script>