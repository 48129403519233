<template>
    <v-list-item :disabled="disableBtn" :to="$validate.DataValid(linkName) ? { name: linkName } : null" v-on="activator"
        v-bind="attrs" :active-class="activeClass" @click="btnClick(name, $event)">
        <slot name="icon-slot">
            <v-list-item-icon v-if="$validate.DataValid(icon)">
                <v-icon :class="icon"></v-icon>
            </v-list-item-icon>
        </slot>
        <v-list-item-content>
            <v-list-item-title>
                {{name}}
            </v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    name: 'NavListItem',
    props: {

        name: {
            type: String,
            default: ''
        },
        linkName: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        activator: {
            type: Object,
            default: () => { }
        },
        attrs: {
            type: Object,
            default: () => { }
        },
        disableBtn: {
            type: Boolean,
            default: false
        },
        activeClass: {
            type: String,
            default: ''
        },
    },
    methods: {
        btnClick(name, event) {
            if (this.$validate.DataValid(this.linkName)) {
                this.$emit('navClick', name);
            }
            this.$emit('btnClick', name);
        }
    }
}
</script>