<template>
  <v-app>
    <v-app-bar flat app color="white" height="60" :style="{ 'margin-left': $vuetify.breakpoint.mdAndDown ? '0' : '8px' }">
      <!-- icon for mobile nav drawer -->
      <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mdAndDown" @click.stop="isDrawerOpen = !isDrawerOpen">
        <v-icon color="colorBlack" class="fi fi-rr-arrow-alt-square-right" style="font-size: 22px"></v-icon>
      </v-app-bar-nav-icon>
      <v-spacer />

      <!-- for desktop app bar -->
      <v-menu flat bottom offset-y nudge-bottom="10" rounded="lg" content-class="elevation-0 elevation-custom"
        transition="slide-y-transition" v-model="isUserMenuOpen" :close-on-content-click="!disableCloseMenu">
        <!-- User Display Button -->
        <template #activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" depressed text class="user-menu-open-button">
            <span>{{ userName }}{{ $validate.DataValid(schoolName) ? ' | ' : '' }}{{ schoolName }}</span>
            <v-icon class="ml-8">
              {{ isUserMenuOpen ? icon.mdiChevronUp : icon.mdiChevronDown }}
            </v-icon>
          </v-btn>
        </template>

        <v-list class="px-2" dense nav>
          <NavListItem class="user-menu-item logout-btn" icon="fi fi-rr-replace"
            @btnClick="() => { schoolSelectDialogOpen = true }" name="切換分校"></NavListItem>
        </v-list>

        <v-list class="px-2" dense nav>
          <NavListItem class="user-menu-item logout-btn" icon="fi fi-rr-sign-in-alt" @btnClick="() => {
            disableCloseMenu = false;
            this.logout();
          }" name="登出"></NavListItem>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!-- for navigation drawer -->
    <NavDrawer :drawerItemList="drawerItems" :isDrawerOpen.sync="isDrawerOpen"></NavDrawer>

    <DialogLayout :openDialog="schoolSelectDialogOpen" title="選擇分校" enableScroll btnAlign="center" eager
      @close="updateSelectedSchool($event)">
      <v-radio-group v-model="tempSelectedSchoolId">
        <v-radio v-for="item in tutorSchoolOptions" :key="item.value" :label="item.text" :value="item.value"
          hide-details></v-radio>
      </v-radio-group>
    </DialogLayout>

    <v-main>
      <div :class="mainContentClass">
        <v-fade-transition mode="out-in">
          <div>
            <template>
              <slot> </slot>
            </template>
          </div>
        </v-fade-transition>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import {
  mdiChevronUp,
  mdiChevronDown
} from '@mdi/js'

import NavListItem from '@/components/NavDrawer/NavListItem.vue';
import NavDrawer from '@/components/NavDrawer/NavDrawer.vue';
import { cannotAccessList } from '@/assets/cannotAccessList'
import DialogLayout from '@/components/layout/DialogLayout.vue';

export default {
  components: {
    NavListItem,
    NavDrawer,
    DialogLayout
  },
  data: () => ({
    icon: {
      mdiChevronUp,
      mdiChevronDown
    },
    isDrawerOpen: false,
    isUserMenuOpen: false,
    disableCloseMenu: false,

    drawerItems: [],

    userId: null,
    userName: '',
    userType: '',

    tutorSchoolOptions: [],
    schoolName: '',
    tempSelectedSchoolId: null,
    userSchoolIds: [],
    schoolSelectDialogOpen: false,

  }),
  computed: {
    mainContentClass() {
      return {
        'fullW-container pt-5 pb-2 px-2': this.$route.name === 'PayTuitionPayment',
        'app-content-container boxed-container py-6 px-3 px-sm-6 px-md-6 px-lg-6 px-xl-6': this.$route.name !== 'PayTuitionPayment',
      };
    },
    getFullPath() {
      return this.$route.path;
    }
  },
  watch: {
    getFullPath() {
      this.getSchoolData();
    }
  },
  methods: {
    initDrawerItems() {
      this.drawerItems = [
        // {
        //   isGroup: false,
        //   id: 'dashboard',
        //   text: '總覽',
        //   linkName: 'Dashboard',
        //   icon: 'fi fi-rr-tachometer-fast',
        //   show: !cannotAccessList['Dashboard'] || !cannotAccessList['Dashboard'].includes(this.userType),
        // },
        {
          isGroup: false,
          id: 'pay-tuition',
          text: '繳交學費',
          linkName: 'PayTuitionList',
          icon: 'fi fi-rr-piggy-bank',
          show: !cannotAccessList['PayTuitionList'] || !cannotAccessList['PayTuitionList'].includes(this.userType),
          subPages: ['PayTuitionPayment']
        },
        {
          isGroup: false,
          id: 'timetable',
          text: '課程時間表',
          linkName: 'Timetable',
          icon: 'fi fi-rr-calendar',
          show: !cannotAccessList['Timetable'] || !cannotAccessList['Timetable'].includes(this.userType),
          subPages: ['CreateClass', 'ClassDetails']
        },
        {
          isGroup: false,
          id: 'student',
          text: '學生管理',
          linkName: 'StudentList',
          icon: 'fi fi-rr-grin',
          show: !cannotAccessList['StudentList'] || !cannotAccessList['StudentList'].includes(this.userType),
          subPages: ['StudentDetails', 'CreateStudent', 'StudentAttendance']
        },
        {
          isGroup: true,
          id: 'course',
          text: '課程管理',
          icon: 'fi fi-rr-graduation-cap',
          show: !cannotAccessList['CourseList'] || !cannotAccessList['CourseList'].includes(this.userType)
            || !cannotAccessList['CourseCategoryList'] || !cannotAccessList['CourseCategoryList'].includes(this.userType),
          items: [
            {
              show: !cannotAccessList['CourseList'] || !cannotAccessList['CourseList'].includes(this.userType),
              text: '所有課程',
              linkName: 'CourseList',
              subPages: ['CourseDetails', 'CreateCourse']
            },
            {
              show: !cannotAccessList['CourseCategoryList'] || !cannotAccessList['CourseCategoryList'].includes(this.userType),
              text: '課程分類',
              linkName: 'CourseCategoryList',
              subPages: ['CourseCategoryDetails', 'CreateCourseCategory']
            },
          ],
        },
        {
          isGroup: false,
          id: 'order',
          text: '訂單紀錄',
          linkName: 'OrderList',
          icon: 'fi fi-rr-donate',
          show: !cannotAccessList['OrderList'] || !cannotAccessList['OrderList'].includes(this.userType),
        },
        {
          isGroup: true,
          id: 'product',
          text: '商品管理',
          icon: 'fi fi-rr-cube',
          show: !cannotAccessList['ProductList'] || !cannotAccessList['ProductList'].includes(this.userType)
            || !cannotAccessList['ProductCategoryList'] || !cannotAccessList['ProductCategoryList'].includes(this.userType),
          items: [
            {
              show: !cannotAccessList['ProductList'] || !cannotAccessList['ProductList'].includes(this.userType),
              text: '所有商品',
              linkName: 'ProductList',
              subPages: ['ProductDetails', 'CreateProduct']
            },
            {
              show: !cannotAccessList['ProductCategoryList'] || !cannotAccessList['ProductCategoryList'].includes(this.userType),
              text: '商品分類',
              linkName: 'ProductCategoryList',
              subPages: ['ProductCategoryDetails', 'CreateProductCategory']
            },
          ]
        },
        {
          isGroup: false,
          id: 'classroom',
          text: '課室管理',
          linkName: 'ClassroomList',
          icon: 'fi fi-rr-door-open',
          show: !cannotAccessList['ClassroomList'] || !cannotAccessList['ClassroomList'].includes(this.userType),
          subPages: ['ClassroomDetails', 'CreateClassroom']
        },
        {
          isGroup: false,
          id: 'school',
          text: '分校管理',
          linkName: 'SchoolList',
          icon: 'fi fi-rr-book-open-cover',
          show: !cannotAccessList['SchoolList'] || !cannotAccessList['SchoolList'].includes(this.userType),
          subPages: ['SchoolDetails', 'CreateSchool']
        },
        {
          isGroup: false,
          id: 'tutor',
          text: '導師管理',
          linkName: 'TutorList',
          icon: 'fi fi-rr-chalkboard-user',
          show: !cannotAccessList['TutorList'] || !cannotAccessList['TutorList'].includes(this.userType),
          subPages: ['TutorDetails', 'CreateTutor']
        },
        {
          isGroup: false,
          id: 'promotion',
          text: '推廣活動',
          linkName: 'PromotionList',
          icon: 'fi fi-rr-gift',
          show: !cannotAccessList['PromotionList'] || !cannotAccessList['PromotionList'].includes(this.userType),
        },
        {
          isGroup: false,
          id: 'setting',
          text: '設定',
          linkName: 'Setting',
          icon: 'fi fi-rr-settings',
          show: !cannotAccessList['Setting'] || !cannotAccessList['Setting'].includes(this.userType),
        },
        {
          isGroup: false,
          id: 'enquiry',
          text: '客戶查詢',
          linkName: 'EnquiryList',
          icon: 'fi fi-rr-mailbox',
          show: !cannotAccessList['EnquiryList'] || !cannotAccessList['EnquiryList'].includes(this.userType),
        },
      ]
    },

    // ==== For render menu bar 分校
    async getSchoolData() {
      try {
        const { data } = await this.$Fetcher.GetSchools();
        const filtered = data.filter(el => el.active === true);
        if (this.$validate.DataValid(filtered)) {
          this.tutorSchoolOptions = filtered.map(el => {
            return {
              value: el.id,
              text: el.name
            }
          })

          await this.getUserSchoolsList();
        }
      } catch(err) {
        this.$common.error(err);
      }
    },

    async getUserSchoolsList() {
      this.tempSelectedSchoolId = null;
      if (this.$validate.DataValid(this.userId)) {
        try {
          const data = await this.$Fetcher.GetTutorById(this.userId);
          let _selectedSchoolId = null;
          const selectedSchoolId = this.getUserSelectedSchool();

          if (this.$validate.DataValid(data.schools)) {
            this.userSchoolIds = this.$common.duplicateData(data.schools);
          } else if (data.user_type === 'developer') {
            // All schools
            this.userSchoolIds = this.tutorSchoolOptions.map(el => el.value);
            await this.updateUserSchools(this.userSchoolIds);
          }

          if (selectedSchoolId === null) {
            if (this.$validate.DataValid(this.userSchoolIds)) {
              _selectedSchoolId = this.userSchoolIds[0];
              await this.updateUserSelectedSchool(this.userSchoolIds[0]);
            }
          } else {
            _selectedSchoolId = selectedSchoolId;
          }

          this.tutorSchoolOptions = this.tutorSchoolOptions.filter(el => this.userSchoolIds.includes(el.value));
          this.tempSelectedSchoolId = _selectedSchoolId;

          this.schoolName = '';
          if (this.$validate.DataValid(_selectedSchoolId)) {
            const target = this.tutorSchoolOptions.find(item => item.value === _selectedSchoolId);
            if (target) {
              this.schoolName = target.text;
            }
          }
        }  catch(err) {
          this.$common.error(err);
        }
      }
    },

    async updateSelectedSchool(action) {
      if (action === true) {
        const updated = await this.updateUserSelectedSchool(this.tempSelectedSchoolId);
        if (updated) {
          let nextName = '';
          for (let i = 0 ; i < this.drawerItems.length; i++) {
            if (this.drawerItems[i].isGroup && this.$validate.DataValid(this.drawerItems[i].items)) {
              this.drawerItems[i].items.forEach(el => {
                if (el.subPages && el.subPages.includes(this.$route.name)) {
                  nextName = el.linkName;
                }
              })

            } else {
              if (this.drawerItems[i].subPages && this.drawerItems[i].subPages.includes(this.$route.name)) {
                nextName = this.drawerItems[i].linkName;
              }
            }

            if (nextName !== '') {
              break;
            }
          }

          if (nextName !== '') {
            this.$router.push({ name: nextName });
          } else {
            this.$router.go();
          }
          
          this.schoolSelectDialogOpen = false;
        } else {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: '更新失敗',
            type: 'error',
            refresh: false,
            redirect: ''
          });
        }
      } else {
        const selectedSchoolId = this.getUserSelectedSchool();
        this.tempSelectedSchoolId = this.$common.duplicateData(selectedSchoolId);
        this.schoolSelectDialogOpen = false;
      }
    }
  },
  async mounted() {
    const userData = await this.getCurrentUserData();
    if (this.$validate.DataValid(userData)) {
      this.userName = userData.username;
      this.userId = userData.id;
      this.getSchoolData();
    }

  },
  async created() {
    const check = await this.checkUserData();
    if (check) {
      this.userType = await this.getUserType();
      this.initDrawerItems();
    }
  },
}
</script>

<style lang="scss" scoped>
.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.fullW-container {
  max-width: calc(100% - 60px);
  margin-left: auto;
  margin-right: auto;
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .fullW-container {
    max-width: calc(100% - 20px);
  }
}
</style>

