import '@/plugins/vue-composition-api'
import '@/styles/fonts.scss'
import '@/styles/styles.scss'
import Vue from 'vue'

import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import api from './api'
import TokenMixin from '@/mixins/TokenMixin'
import PageHeaderSlot from './components/PageHeaderSlot.vue'
import Fetcher from '@/prototype/Fetcher'
import Formatter from '@/prototype/Formatter'
import Validator from '@/prototype/Validator'
import CommonFn from '@/prototype/CommonFn'


Vue.config.productionTip = false

Vue.use(api)
Vue.use(CommonFn)
Vue.use(Validator)
Vue.use(Formatter)
Vue.use(Fetcher)
Vue.mixin(TokenMixin)

Vue.component('PageHeaderSlot', PageHeaderSlot)
Vue.prototype.$mediaPath = window.location.hostname.indexOf('grandfieldlifeeducation.com') >= 0 ? 'https://system.grandfieldlifeeducation.com/media/' : 'https://tutoring.innpressionhost.com/media/';

Vue.prototype.$settings = {
  startTime: '08:00',
  endTime: '20:00',
  interval: 30,
}

Vue.prototype.$genderOptions = [
  { value: 'M', text: '男' },
  { value: 'F', text: '女' },
];

const subjectOptions = [
  { value: 'chinese', text: '中文' },
  { value: 'english', text: '英文' },
  { value: 'mathematics', text: '數學' },
  { value: 'general_studies', text: '常識' },
  { value: 'economy', text: '經濟' },
  { value: 'biology', text: '生物' },
  { value: 'chemistry', text: '化學' },
  { value: 'physic', text: '物理' },
  { value: 'citizenship_social_development', text: '公民與社會發展' },
  { value: 'geography', text: '地理' },
  { value: 'history', text: '歷史' },
  { value: 'chinese_history', text: '中國歷史' },
  { value: 'others', text: '其他' },
];
Vue.prototype.$subjectOptions = subjectOptions;

const grades = {
  P1: '小一',
  P2: '小二',
  P3: '小三',
  P4: '小四',
  P5: '小五',
  P6: '小六',
  S1: '中一',
  S2: '中二',
  S3: '中三',
  S4: '中四',
  S5: '中五',
  S6: '中六',
};


Vue.prototype.$grade = grades;
Vue.prototype.$gradeOptions = Object.keys(grades).map(key => {
  return { value: key, text: grades[key] };
});

Vue.prototype.$memberOptions = [
  { value: 'normal', text: '普通會員' },
  { value: 'vip', text: 'VIP' },
];

const orderType = {
  course: '課程',
  product: '商品',
};
Vue.prototype.$orderType = orderType;

Vue.filter('formatOrderStatus', function (value) {
  if (!value) return '';

  const status = {
    'pending': '待付款',
    'paid': '已付款',
    'expired': '逾期未付款',
    'cancelled': '已取消'
  }

  const statusText = status[value];

  return statusText !== undefined && statusText !== null ? statusText : value;
})

Vue.filter('formatGrade', function (value) {
  if (!value) return '';

  const text = grades[value];

  return text !== undefined && text !== null ? text : value;
})

Vue.filter('formatAttendanceStatus', function (value) {
  if (!value) return '';

  const status = {
    'pending': '待定',
    'attend': '出席',
    'absent': '缺席'
  }

  const statusText = status[value];

  return statusText !== undefined && statusText !== null ? statusText : value;
})

Vue.filter('formatOrderType', function (value) {
  if (!value) return '';

  const status = orderType;
  const statusText = status[value];
  return statusText !== undefined && statusText !== null ? statusText : value;
})

Vue.filter('formatSubject', function (value) {
  if (!value) return '';

  const target = subjectOptions.find(el => el.value === value);
  if (target) {
    return target.text;
  }

  return value;
})


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
