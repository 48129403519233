const SET_DIALOG_LINK = 'SET_LEAVE_DIALOG_LINK';
const SHOW_DIALOG = 'SHOW_LEAVE_DIALOG';

export default {
   state: {
      showDialog: false,
      toLink: null,
   },
   actions: {
      setLeaveDialogLink: ({ commit }, value) => {
         commit(SET_DIALOG_LINK, value);
      },

      setShowLeaveDialog: ({ commit }, value) => {
         commit(SHOW_DIALOG, value);
      }
   },
   mutations: {
      [SET_DIALOG_LINK]: (state, value) => {
         state.toLink = value;
      },
      [SHOW_DIALOG]: (state, value) => {
         state.showDialog = value;
      },
   },
}