<script>
import Cookies from 'js-cookie'
import { mapActions } from 'vuex';
import { cannotAccessList } from '@/assets/cannotAccessList';
export default {
  name: 'TokenMixin',
  methods: {
    ...mapActions(['setDialogMessage', 'setShowDialog']),
    getCurrentUserData() {
      const data = Cookies.get('TUTOR_CMS_LID')

      if (data !== undefined && data !== null && data !== '') {
        return JSON.parse(Cookies.get('TUTOR_CMS_LID'))
      }

      return {}
    },
    /**
     * @param {Object} data - { id: -1, user_type: '', username: '', schools: [], selected_school: -1, last_login: new Date().getTime() }
     */
    setUserData(data) {
      const userData = this.getCurrentUserData();
      if (this.$validate.DataValid(userData)) {
        Cookies.remove('TUTOR_CMS_LID');
      }

      Cookies.set('TUTOR_CMS_LID', JSON.stringify(data), {
        path: '/',
        expires: new Date(new Date().getTime() + 2 * 60 * 60 * 1000), // expires after 2 hour
        sameSite: 'strict',
        secure: true,
      })
    },

    getUserType() {
      // 'developer' | 'manager' | 'admin' | 'tutor'
      const userData = this.getCurrentUserData();
      return userData.user_type;
    },
    getUserSchools() {
      const userData = this.getCurrentUserData();
      return userData.schools || [];
    },
    getUserSelectedSchool() {
      const userData = this.getCurrentUserData();
      if (this.$validate.DataValid(userData.schools) && this.$validate.DataValid(userData.selected_school) && userData.schools.includes(userData.selected_school)) {
        return userData.selected_school;
      }

      return null;
    },
    async updateUserSchools(schools) {
      const check = this.checkUserData();
      if (check) {
        const userData = this.getCurrentUserData();
        const newData = {
          ...userData,
          schools: schools
        }

        await this.setUserData(newData);

        return true;
      }

      return false;
    },
    async updateUserSelectedSchool(selectedSchool) {
      const check = this.checkUserData();
      if (check) {
        const userData = this.getCurrentUserData();
        const newData = {
          ...userData,
          selected_school: selectedSchool
        }

        await this.setUserData(newData);

        return true;
      }

      return false;
    },

    async logout() {
      await this.removeUserCookies();
      this.$router.replace({ name: 'login' });
    },
    removeUserCookies() {
      const userData = this.getCurrentUserData();
      if (this.$validate.DataValid(userData)) {
        Cookies.remove('TUTOR_CMS_LID')
      }
    },
    checkUserData() {
      let resultType = false;
      const userData = this.getCurrentUserData();

      if (this.$validate.DataValid(userData)) {
        if (
          this.$validate.DataValid(userData.id) &&
          this.$validate.DataValid(userData.username) &&
          this.$validate.DataValid(userData.user_type)
        ) {
          resultType = true;
        }
      }

      if (!resultType) {
        this.forceLogout()
      } else {
        const linkName = this.$route.name;
        if (linkName && cannotAccessList[linkName] && cannotAccessList[linkName].length > 0 && cannotAccessList[linkName].includes(userData.user_type)) {
          resultType = false;
          this.setDialogMessage({
            message: 'noPermission',
            userType: userData.user_type
          });
          this.setShowDialog(true);
          this.$store.dispatch('toggleLoadingPage', false)
        }
      }

      return resultType;
    },
    async forceLogout() {
      this.$store.dispatch('toggleLoadingPage', true)

      const userData = this.getCurrentUserData()
      if (this.$validate.DataValid(userData)) {
        Cookies.remove('TUTOR_CMS_LID')
      }

      this.$store.dispatch('toggleAlertMessage', {
        show: true,
        message: '登入過期，請重新登入',
        type: 'error',
        refresh: false,
        redirect: 'login',
      })
    },
  },
}
</script>
