/* eslint-disable no-unused-vars */
import Vue from 'vue'
import VueRouter from 'vue-router';
import store from '@/store'
import {
  cannotAccessList,
  defaultPage
} from '@/assets/cannotAccessList';

import TokenMixin from '@/mixins/TokenMixin'
import Validator from '@/prototype/Validator'
import Error from '@/views/Error.vue';

Vue.use(VueRouter);
Vue.mixin(TokenMixin)
Vue.use(Validator)
const vm = new Vue();

const routes = [
  {
    path: '/',
    redirect: 'login',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
      title: '登入',
      skipAuth: true,
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/pages/user/Dashboard.vue'),
    meta: {
      layout: 'content',
      title: '總覽',
      requireAuth: true,
      cannotAccess: cannotAccessList['Dashboard'],
    },
  },
  {
    path: '/pay-tuition',
    name: 'PayTuitionList',
    component: () => import('@/views/pages/user/PayTuition/PayTuitionList.vue'),
    meta: {
      layout: 'content',
      title: '繳交學費',
      requireAuth: true,
      cannotAccess: cannotAccessList['PayTuitionList'],
    },
  },
  {
    path: '/pay-tuition/payment/:id',
    name: 'PayTuitionPayment',
    component: () => import('@/views/pages/user/PayTuition/PayTuitionPayment.vue'),
    meta: {
      layout: 'content',
      title: '繳交學費',
      requireAuth: true,
      cannotAccess: cannotAccessList['PayTuitionPayment'],
    },
  },
  {
    path: '/timetable',
    name: 'Timetable',
    component: () => import('@/views/pages/user/CourseTimetable/Timetable.vue'),
    meta: {
      layout: 'content',
      title: '課程時間表',
      requireAuth: true,
      cannotAccess: cannotAccessList['Timetable'],
    },
  },
  {
    path: '/timetable/class/create',
    name: 'CreateClass',
    component: () => import('@/views/pages/user/CourseTimetable/CreateClass.vue'),
    meta: {
      layout: 'content',
      title: '新增課程時間表',
      requireAuth: true,
      cannotAccess: cannotAccessList['CreateClass'],
    },
  },
  {
    path: '/timetable/lesson/:id',
    name: 'ClassDetails',
    component: () => import('@/views/pages/user/CourseTimetable/ClassDetails.vue'),
    meta: {
      layout: 'content',
      title: '課程時間表',
      requireAuth: true,
      cannotAccess: cannotAccessList['ClassDetails'],
    },
  },
  {
    path: '/student',
    name: 'StudentList',
    component: () => import('@/views/pages/user/Student/StudentList.vue'),
    meta: {
      layout: 'content',
      title: '學生管理',
      requireAuth: true,
      cannotAccess: cannotAccessList['StudentList'],
    },
  },
  {
    path: '/student/details/:id',
    name: 'StudentDetails',
    component: () => import('@/views/pages/user/Student/StudentDetails.vue'),
    meta: {
      layout: 'content',
      title: '學生詳情',
      requireAuth: true,
      cannotAccess: cannotAccessList['StudentDetails'],
    },
  },
  {
    path: '/student/create',
    name: 'CreateStudent',
    component: () => import('@/views/pages/user/Student/StudentDetails.vue'),
    meta: {
      layout: 'content',
      title: '新增學生',
      requireAuth: true,
      cannotAccess: cannotAccessList['CreateStudent'],
    },
  },
  {
    path: '/student/attendance/:id',
    name: 'StudentAttendance',
    component: () => import('@/views/pages/user/Student/StudentAttendance.vue'),
    meta: {
      layout: 'content',
      title: '上堂紀錄',
      requireAuth: true,
      cannotAccess: cannotAccessList['StudentAttendance'],
    },
  },
  {
    path: '/course',
    name: 'CourseList',
    component: () => import('@/views/pages/user/Course/CourseList.vue'),
    meta: {
      layout: 'content',
      title: '所有課程',
      requireAuth: true,
      cannotAccess: cannotAccessList['CourseList'],
    },
  },
  {
    path: '/course/details/:id',
    name: 'CourseDetails',
    component: () => import('@/views/pages/user/Course/CourseDetails.vue'),
    meta: {
      layout: 'content',
      title: '課程詳情',
      requireAuth: true,
      cannotAccess: cannotAccessList['CourseDetails'],
    },
  },
  {
    path: '/course/create',
    name: 'CreateCourse',
    component: () => import('@/views/pages/user/Course/CourseDetails.vue'),
    meta: {
      layout: 'content',
      title: '新增課程',
      requireAuth: true,
      cannotAccess: cannotAccessList['CreateCourse'],
    },
  },
  {
    path: '/course-category',
    name: 'CourseCategoryList',
    component: () => import('@/views/pages/user/Course/CourseCategoryList.vue'),
    meta: {
      layout: 'content',
      title: '課程分類',
      requireAuth: true,
      cannotAccess: cannotAccessList['CourseCategoryList'],
    },
  },
  {
    path: '/course-category/create',
    name: 'CreateCourseCategory',
    component: () => import('@/views/pages/user/Course/CourseCategoryDetails.vue'),
    meta: {
      layout: 'content',
      title: '新增分類',
      requireAuth: true,
      cannotAccess: cannotAccessList['CreateCourseCategory'],
    },
  },
  {
    path: '/course-category/details/:id',
    name: 'CourseCategoryDetails',
    component: () => import('@/views/pages/user/Course/CourseCategoryDetails.vue'),
    meta: {
      layout: 'content',
      title: '課程分類詳情',
      requireAuth: true,
      cannotAccess: cannotAccessList['CourseCategoryDetails'],
    },
  },
  {
    path: '/order',
    name: 'OrderList',
    component: () => import('@/views/pages/user/Order/OrderList.vue'),
    meta: {
      layout: 'content',
      title: '近期帳單',
      requireAuth: true,
      cannotAccess: cannotAccessList['OrderList'],
    },
  },
  {
    path: '/product',
    name: 'ProductList',
    component: () => import('@/views/pages/user/Product/ProductList.vue'),
    meta: {
      layout: 'content',
      title: '商品管理',
      requireAuth: true,
      cannotAccess: cannotAccessList['ProductList'],
    },
  },
   {
    path: '/product/details/:id',
    name: 'ProductDetails',
    component: () => import('@/views/pages/user/Product/ProductDetails.vue'),
    meta: {
      layout: 'content',
      title: '商品詳情',
      requireAuth: true,
      cannotAccess: cannotAccessList['ProductDetails'],
    },
  },
  {
    path: '/product/create',
    name: 'CreateProduct',
    component: () => import('@/views/pages/user/Product/ProductDetails.vue'),
    meta: {
      layout: 'content',
      title: '新增商品',
      requireAuth: true,
      cannotAccess: cannotAccessList['CreateProduct'],
    },
  },
  {
    path: '/product-category',
    name: 'ProductCategoryList',
    component: () => import('@/views/pages/user/Product/ProductCategoryList.vue'),
    meta: {
      layout: 'content',
      title: '商品分類',
      requireAuth: true,
      cannotAccess: cannotAccessList['ProductCategoryList'],
    },
  },
  {
    path: '/product-category/details/:id',
    name: 'ProductCategoryDetails',
    component: () => import('@/views/pages/user/Product/ProductCategoryDetails.vue'),
    meta: {
      layout: 'content',
      title: '商品分類詳情',
      requireAuth: true,
      cannotAccess: cannotAccessList['ProductCategoryDetails'],
    },
  },
  {
    path: '/product-category/create',
    name: 'CreateProductCategory',
    component: () => import('@/views/pages/user/Product/ProductCategoryDetails.vue'),
    meta: {
      layout: 'content',
      title: '新增分類',
      requireAuth: true,
      cannotAccess: cannotAccessList['CreateProductCategory'],
    },
  },
  {
    path: '/classroom',
    name: 'ClassroomList',
    component: () => import('@/views/pages/user/Classroom/ClassroomList.vue'),
    meta: {
      layout: 'content',
      title: '課室管理',
      requireAuth: true,
      cannotAccess: cannotAccessList['ClassroomList'],
    },
  },
  {
    path: '/classroom/details/:id',
    name: 'ClassroomDetails',
    component: () => import('@/views/pages/user/Classroom/ClassroomDetails.vue'),
    meta: {
      layout: 'content',
      title: '課室詳情',
      requireAuth: true,
      cannotAccess: cannotAccessList['ClassroomDetails'],
    },
  },
  {
    path: '/classroom/create',
    name: 'CreateClassroom',
    component: () => import('@/views/pages/user/Classroom/ClassroomDetails.vue'),
    meta: {
      layout: 'content',
      title: '新增課室',
      requireAuth: true,
      cannotAccess: cannotAccessList['CreateClassroom'],
    },
  },
  {
    path: '/school',
    name: 'SchoolList',
    component: () => import('@/views/pages/user/School/SchoolList.vue'),
    meta: {
      layout: 'content',
      title: '分校管理',
      requireAuth: true,
      cannotAccess: cannotAccessList['SchoolList'],
    },
  },
  {
    path: '/school/details/:id',
    name: 'SchoolDetails',
    component: () => import('@/views/pages/user/School/SchoolDetails.vue'),
    meta: {
      layout: 'content',
      title: '分校詳情',
      requireAuth: true,
      cannotAccess: cannotAccessList['SchoolDetails'],
    },
  },
  {
    path: '/school/create',
    name: 'CreateSchool',
    component: () => import('@/views/pages/user/School/SchoolDetails.vue'),
    meta: {
      layout: 'content',
      title: '新增分校',
      requireAuth: true,
      cannotAccess: cannotAccessList['CreateSchool'],
    },
  },
  {
    path: '/tutor',
    name: 'TutorList',
    component: () => import('@/views/pages/user/Tutor/TutorList.vue'),
    meta: {
      layout: 'content',
      title: '導師管理',
      requireAuth: true,
      cannotAccess: cannotAccessList['TutorList'],
    },
  },
  {
    path: '/tutor/details/:id',
    name: 'TutorDetails',
    component: () => import('@/views/pages/user/Tutor/TutorDetails.vue'),
    meta: {
      layout: 'content',
      title: '導師詳情',
      requireAuth: true,
      cannotAccess: cannotAccessList['TutorDetails'],
    },
  },
  {
    path: '/tutor/create',
    name: 'CreateTutor',
    component: () => import('@/views/pages/user/Tutor/TutorDetails.vue'),
    meta: {
      layout: 'content',
      title: '新增導師',
      requireAuth: true,
      cannotAccess: cannotAccessList['CreateTutor'],
    },
  },
  {
    path: '/promotion',
    name: 'PromotionList',
    component: () => import('@/views/pages/user/Dashboard.vue'),
    meta: {
      layout: 'content',
      title: '推廣活動',
      requireAuth: true,
      cannotAccess: cannotAccessList['PromotionList'],
    },
  },
  {
    path: '/setting',
    name: 'Setting',
    component: () => import('@/views/pages/user/Dashboard.vue'),
    meta: {
      layout: 'content',
      title: '設定',
      requireAuth: true,
      cannotAccess: cannotAccessList['Setting'],
    },
  },
  {
    path: '/enquiry',
    name: 'EnquiryList',
    component: () => import('@/views/pages/user/Dashboard.vue'),
    meta: {
      layout: 'content',
      title: '客戶查詢',
      requireAuth: true,
      cannotAccess: cannotAccessList['EnquiryList'],
    },
  },
  {
    path: '*',
    name: 'Error',
    component: Error,
    meta: { title: 'Error' },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  store.dispatch('toggleLoadingPage', false);
  store.dispatch('setLoading', false);
  window.scrollTo(0, 0);

  if (to.meta.requireAuth) {
    const userData = vm.getCurrentUserData();
    const isUserLogin = vm.$validate.DataValid(userData);

    if (isUserLogin) {
      if (to.meta.cannotAccess && to.meta.cannotAccess.includes(userData.user_type)) {
        next(defaultPage[userData.user_type])
      } else {
        next()
      }
    } else {
      next('/login')
    }
  } else if (to.meta.skipAuth) {
    const userData = vm.getCurrentUserData();
    if (vm.$validate.DataValid(userData) && userData.user_type) {
      if (to.name === 'login' || to.meta.cannotAccess && to.meta.cannotAccess.includes(userData.user_type)) {
        next(defaultPage[userData.user_type]);
      } else {
        next()
      }
    } else {
      next()
    }
  } else {
    next()
  }
})



const DEFAULT_TITLE = 'CMS'

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = (`${to.meta.title} - ` || '') + DEFAULT_TITLE
  })
})

export default router
