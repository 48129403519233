const pad = (n, l, c, r, b) => {
    var s = n.toString(),
        l = l || 2,
        r = r !== false,
        b = b !== false;
    if (s.length > 0) {
        var p = "";
        if (!isNaN(n) && s.charAt(0) == "-") {
            p = "-";
            s = s.substring(1);
        }
        while (s.length < l) {
            if (r) s = (c || "0") + s;
            else s += c || "0";
        }
        return b ? p + s : s;
    } else return "";
}

// cms: backend
const COURSE_MODEL = {
    "course_code": "course_code",
    "grade": "grade",
    "category_id": "course_category_id",
    "schools": "center"
};

const COURSE_CATEGORY_MODEL = {
    "name": "name",
    "category_code": "category_code",
    "schools": "center",
};

const CLASSROOM_MODEL = {
    "name": "name",
    "school_id": "center_id",
};

const PRODUCT_CATEGORY_MODEL = {
    "name": "name",
};

const PRODUCT_MODEL = {
    "name": "name",
    "product_code": "product_code",
    "status": "status",
    "school_id": "center_id",
    "category_id": "category",
    "inventory": "inventory",
    "price": "price",
    "cost": "cost",
};

const SCHOOL_MODEL = {
    "name": "name",
    "address": "address",
    "logo": "logo",
    "school_code": "center_code",
    "invoice_terms": "terms",
};

const STUDENT_MODEL = {
    "status": "status",
    "school_id": "center_id",
    "student_name": "student_name",
    "student_eng_name": "student_name_en",
    "address": "address",
    "gender": "gender",
    "phone": "phone",
    "birth_date": "birthday",
    "username": "username",
    "password": "password_data",
    "contact_person_1_name": "contact_1_name",
    "contact_person_1_phone": "contact_1_phone",
    "contact_person_2_name": "contact_2_name",
    "contact_person_2_phone": "contact_2_phone",
    "student_school_name": "school",
    "member_level": "level",
    "grade": "grade",

};

const STUDENT_SCORE_MODEL = {
    "student_id": "student_id",
    "exam_name": "name",
    "grade": "grade",
    "semester": "semester",
    "subject": "subject",
    "score": "score"
}

const TUTOR_MODEL = {
    "name": "name",
    "user_type": "staff_role",
    "schools": "center",
    "gender": "gender",
    "username": "username",
    "password": "password_data",
    "phone": "phone",
    "remark": "remark",
};

const CLASS_MODEL = { // for create lesson
    "course_id": "course_id",
    "course_code": "course_code",
    "course_name": "name",
    "tutor_id": "staff_id",
    "classroom_id": "facility_id",
    "color": "color",
    "salary_method": "salary_method", // 'by_lesson' | 'by_student_num'
    "max_student": "capacity",
    "school_id": "center_id",
    "price": "price",
    "start_time": "start_time",
    "end_time": "end_time",
    "grade": "grade",
    "lesson_type": "lesson_type",

    // For single date lesson
    "single_date": "date",

    // For general date lesson
    "regular_period": "generate_period", //  'weekly' | 'bi-weekly'
    "regular_weekdays": "weekday",
    "start_date": "start_date",
    "end_date": "end_date",
};

const LESSON_MODEL = { // for edit lesson
    "date": "date",
    "start_time": "start_time",
    "end_time": "end_time",
    "tutor_id": "staff_id",
    "classroom_id": "facility_id",
    "attachment_list": "attachment",
    "attendance_list": "attendance", // { student_id: -1, status: 'pending' | 'attend' | 'absent' }
}

const ORDER_MODEL = {
    "student_id": "student_id",
    "school_id": "center_id",
    "remark": "remark",
    "payment_method": "payment_method",
    "lesson_id_list": "lesson_id_list",
    "product_id_list": "product_id_list",
    "subtotal": "subtotal",
    "status": "status",
    "is_new": "is_new",
    "discount_type": "discount_type",
    "discount_value": "discount_value",
};

const convertCMSKeyToBackendKey = (payload, model) => {
    if (payload && Object.keys(payload).length > 0) {
        let obj = {};

        for (const key in payload) {
            if (model[key] !== undefined && model[key] !== null && model[key] !== '') {
                obj[model[key]] = payload[key];
            } else {
                obj[key] = payload[key];
            }
        }

        return obj;
    }

    return {};
}

// backend: cms
const CMS_STUDENT_STATUS = {
    "active": "active",
    "quit": "inactive"
}

const CMS_ORDER_STATUS = {
    "pending": "pending",
    "paid": "paid",
    "expired": "expired",
    "cancelled": "cancelled"
}

const CMS_ATTENDANCE_STATUS = {
    "pending": "pending",
    "attend": "attend",
    "absent": "absent",
    "reschedule": "reschedule",
    "drop": "drop",
    "swap": "swap"
}

const parseAccountData = (data) => {
    if (data) {
        return {
            id: data.id,
            username: data.username,
            active: data.active,
            create_date: data.create_date,
            name: data.name,
            user_type: data.staff_role,
            schools: data.center,
            gender: data.gender,
            phone: data.phone,
            remark: data.remark,
        }
    }

    return null;
}

const parseTutorData = (data) => {
    if (data) {
        return {
            id: data.id,
            username: data.username,
            active: data.active,
            name: data.name,
            user_type: data.staff_role,
            schools: data.center,
            gender: data.gender,
            phone: data.phone,
            remark: data.remark,
        };
    }

    return null;
}

const parseStudentData = (data) => {
    if (data) {
        return {
            id: data.id,
            active: data.active,
            status: CMS_STUDENT_STATUS[data.status] || 'active', // active | quit
            school_id: data.center_id,
            student_name: data.student_name || '', // required
            student_eng_name: data.student_name_en || '',
            address: data.address || '',
            gender: data.gender || '',
            phone: data.phone || '', // required
            contact_person_1_name: data.contact_1_name,
            contact_person_1_phone: data.contact_1_phone,
            contact_person_2_name: data.contact_2_name,
            contact_person_2_phone: data.contact_2_phone,
            reg_date: data.create_date ? data.create_date.substring(0, 10) : '',
            student_school_name: data.school || '',
            member_level: data.level || '',
            grade: data.grade || '',
            birth_date: data.birthday || '', // YYYY-MM-DD
            username: data.username || '',
            order_total: data.order_datas !== undefined && Array.isArray(data.order_datas) ? data.order_datas.length : null, // 要用GetStudents先會出，GetStudentById唔會有
        };
    }

    return null;
}

const parseStudentScoreData = (data) => {
    if (data) {
        return {
            id: data.id,
            create_date: data.create_date.substring(0, 10),
            active: data.active,
            student_id: data.student_id,
            exam_name: data.name,
            grade: data.grade,
            semester: data.semester,
            subject: data.subject,
            score: data.score,
        };
    }

    return null;
}

const parseStudentAttendanceData = (data, student_id) => {
    if (data) {
        const targetAttendance = data.attendance.find(el => el.student_id === student_id);
        let attendStatus = 'pending';
        if (targetAttendance) {
            attendStatus = CMS_ATTENDANCE_STATUS[targetAttendance.status] || 'pending';
        }
        return {
            lesson_id: data.id,
            student_id: student_id,
            date: data.date,
            course_code: data.course_code,
            course_name: data.name,
            attend_status: attendStatus,
            start_time: data.start_time,
            end_time: data.end_time,
        };
    }

    return null;
}

const parseSchoolData = (data) => {
    if (data) {
        return {
            id: data.id,
            active: data.active,
            name: data.name,
            address: data.address,
            logo: data.logo,
            school_code: data.center_code,
            invoice_terms: data.terms,
        };
    }

    return null;
}

const parseClassroomData = (data, schoolData = []) => {
    if (data) {
        let targetSchoolData = null;
        if (schoolData.length > 0) {
            targetSchoolData = schoolData.find(el => el.id === data.center_id);
        }
        return {
            id: data.id,
            active: data.active,
            name: data.name,
            school_id: data.center_id,
            school_data: targetSchoolData
        };
    }

    return null;
}

const parseProductCategoryData = (data) => {
    if (data) {
        return {
            id: data.id,
            active: data.active,
            name: data.name,
        };
    }

    return null;
}

const parseProductData = (data, categoryData = []) => {
    if (data) {
        let targetCate = null;
        if (categoryData.length > 0) {
            targetCate = categoryData.find(el => el.id === data.category);
        }

        return {
            id: data.id,
            product_code: data.product_code,
            active: data.active,
            status: data.status, // 'active' | 'inactive'
            name: data.name,
            school_id: data.center_id,
            category_id: data.category,
            category_data: targetCate,
            inventory: data.inventory,
            price: data.price,
            cost: data.cost
        };
    }

    return null;
}

const parseCourseCategoryData = (data, schoolData = []) => {
    if (data) {
        const schoolList = [];

        if (schoolData.length > 0) {
            data.center.forEach(id => {
                const target = schoolData.find(el => el.id === id);
                if (target) {
                    schoolList.push(target);
                }
            })
        }

        return {
            id: data.id,
            active: data.active,
            name: data.name,
            category_code: data.category_code,
            schools: data.center,
            school_datas: schoolList,
        };
    }

    return null;
}

const parseCourseData = (data, schoolData = [], categoryData = []) => {
    if (data) {
        const schoolList = [];

        if (schoolData.length > 0) {
            data.center.forEach(id => {
                const target = schoolData.find(el => el.id === id);
                if (target) {
                    schoolList.push(target);
                }
            })
        }

        let targetCate = null;
        if (categoryData.length > 0) {
            targetCate = categoryData.find(el => el.id === data.course_category_id);
        }

        return {
            id: data.id,
            active: data.active,
            // course_code: data.course_code,
            grade: data.grade,
            category_id: data.course_category_id,
            category_data: targetCate,
            schools: data.center,
            school_datas: schoolList,
        }
    }

    return null;
}

const parseLessonData = (data, tutorData = [], studentData = [], attendanceLessonData = []) => {
    if (data) {
        let targetTutor = null;
        if (tutorData.length > 0) {
            targetTutor = tutorData.find(el => el.id === data.staff_id);
        }


        let attendanceList = [];
        const activeStudents = data.students && Array.isArray(data.students) ? data.students.filter(id => id !== null) : [];

        if (data.attendance !== undefined && Array.isArray(data.attendance)) {
            attendanceList = data.attendance.map(el => {
                const targetStudent = studentData.find(student => student.id === el.student_id);
                const targetFromLesson = attendanceLessonData.find(lesson => lesson.id === el.from_lesson);
                return {
                    student_id: el.student_id,
                    status: CMS_ATTENDANCE_STATUS[el.status] || 'pending',
                    student_name: targetStudent ? targetStudent.student_name : '',
                    gender: targetStudent ? targetStudent.gender : '',
                    switch_from: el.from_course !== undefined && el.from_course !== null && el.from_course !== '' && el.from_course !== -1 ? el.from_course : (targetFromLesson ? `${targetFromLesson.course_name} (${targetFromLesson.date})` : null),
                }
            })
        }

        return {
            id: data.id,
            active: data.active,
            lesson_type: data.lesson_type,
            regular_id: data.regular_id,
            course_id: data.course_id,
            course_code: data.course_code,
            course_name: data.name,
            course_data: data.course_data ? data.course_data : null,
            max_student: data.capacity,
            current_student_num: activeStudents.length,
            school_id: data.center_id,
            color: data.color,
            start_time: data.start_time,
            end_time: data.end_time,
            classroom_id: data.facility_id,
            classroom_data: data.facility_data ? data.facility_data : null,
            price: data.price,
            salary_method: data.salary_method,
            tutor_id: data.staff_id,
            tutor_data: targetTutor,
            date: data.date,
            attachment_list: data.attachment, // { name: '', file: '' } 
            grade: data.grade ? data.grade : data.course_data ? data.course_data.grade : '',
            all_attendance_list: attendanceList, // included all students with any attend status
            attendance_list: attendanceList.filter(el => el.status !== 'drop' && el.status !== 'swap' && el.status !== 'reschedule'), // attendance with status NOT equals to 'drop' | 'swap' | 'reschedule'
            student_ids: activeStudents, // students with status NOT equals to 'drop' | 'swap' | 'reschedule'
        }
    }

    return null;
}

const paresRegularSetting = (data) => {
    if (data) {
        return {
            id: data.id,
            active: data.active,
            lesson_type: data.lesson_type,
            course_id: data.course_id,
            course_code: data.course_code,
            course_name: data.name,
            max_student: data.capacity,
            school_id: data.center_id,
            color: data.color,
            start_time: data.start_time,
            end_time: data.end_time,
            classroom_id: data.facility_id,
            price: data.price,
            salary_method: data.salary_method,
            tutor_id: data.staff_id,
            grade: data.grade,
            regular_period: data.generate_period,
            regular_weekdays: data.weekday,
            start_date: data.start_date,
            end_date: data.end_date,
        }
    }

    return null;
}

const parsePayTuitionData = (data) => {
    if (data) {
        let status = 'pending';
        if (data.order_datas && data.order_datas.length > 0) {
            data.order_datas.sort((a, b) => new Date(a.create_date).getTime() - new Date(b.create_date).getTime());

            for (let i = 0; i < data.order_datas.length; i++) {
                status = data.order_datas[i].status;

                if (data.order_datas[i].status !== 'paid') {
                    const date = new Date();
                    const today = new Date(`${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T12:00:00+08:00`);
                    const createDate = new Date(`${data.order_datas[i].create_date.substring(0, 10)}T12:00:00+08:00`);
                    const diffDays = Math.floor((today.getTime() - createDate.getTime()) / (1000 * 3600 * 24));

                    if (diffDays > 30) {
                        status = 'expired';
                        break;
                    }
                }
            }
        }

        return {
            student_id: data.id,
            school_id: data.center_id,
            grade: data.grade || '',
            student_name: data.student_name || '',
            student_school_name: data.school || '',
            gender: data.gender || '',
            phone: data.phone || '',
            reg_date: data.create_date ? data.create_date.substring(0, 10) : '',
            order_status: CMS_ORDER_STATUS[status] || 'pending',
        };
    }

    return null;
}

const parseOrderData = (data) => {
    if (data) {
        let order_type = '';
        if (Array.isArray(data.product_id_list) && data.product_id_list.length > 0) {
            order_type = 'product';
        } else if (Array.isArray(data.lesson_id_list) && data.lesson_id_list.length > 0) {
            order_type = 'course';
        }

        let itemDatas = [];
        if (order_type === 'course') {
            itemDatas = data.lesson_datas.map(parseLessonData);
        } else if (order_type === 'product') {
            itemDatas = data.product_datas.map(parseProductData);
        }

        return {
            id: data.id,
            active: data.active,
            order_type: order_type,
            payment_method: data.payment_method,
            status: CMS_ORDER_STATUS[data.status] || 'pending',
            remark: data.remark,
            subtotal: data.subtotal,
            item_list: order_type === 'course' ? data.lesson_id_list : order_type === 'product' ? data.product_id_list : [],
            item_datas: itemDatas,
            school_id: data.center_id,
            student_id: data.student_id,
            month: data.create_date.substring(5, 7),
        };
    }

    return null;
}

const displayError = (res) => {
    if (res.data !== undefined && res.data !== null) {
        if (typeof res.data === 'string') {
            return res.data;
        } else {
            return JSON.stringify(res.data);
        }
    }

    return JSON.stringify(res);
}


export default {
    install(Vue, option) {
        Vue.prototype.$Fetcher = new (function () {
            const DataValid = Vue.prototype.$validate.DataValid;
            const devLog = Vue.prototype.$common.log;

            /**
             * 
             * @param {String} upload_data - base64
             * @param {String} upload_file_type - e.g. jpg
             */
            this.UploadFile = async function (payload) {
                try {
                    const data = await Vue.prototype.$XHR.api('upload_file', payload);
                    if (typeof data === 'string') {
                        devLog('----------> XHR [SUCCESS]: UploadFile');
                        devLog(data)
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: UploadFile');
                        return Promise.reject('發生錯誤，請與我們聯絡');
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: UploadFile');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.Login = async function (username, password) {
                try {
                    const uploaded = {
                        username,
                        password
                    }
                    const data = await Vue.prototype.$XHR.api('staff_login', uploaded);
                    const formattedData = parseAccountData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: Login');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: Login');
                        return Promise.reject('發生錯誤，請與我們聯絡');
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: Login');
                    devLog(res);
                    switch (res.data) {
                        case 'login fail':
                            return Promise.reject('電郵或密碼錯誤');
                        default:
                            return Promise.reject(displayError(res));
                    }
                }
            }

            /**
             * 新增Account/導師
             * ===== Required =====
             * @param {String} password_data
             * @param {String} username
             * 
             */
            this.NewAccount = async function (payload) {
                if (Object.prototype.hasOwnProperty.call(payload, 'active')) {
                    delete payload['active'];
                }

                try {
                    const formattedPayload = convertCMSKeyToBackendKey(payload, TUTOR_MODEL);
                    const uploaded = {
                        google_sync: false,
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('new_staff', uploaded);
                    const formattedData = parseAccountData(data);
                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: NewAccount');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: NewAccount');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewAccount');
                    devLog(res);
                    switch (res.data) {
                        case "username exist":
                            return Promise.reject("帳戶已存在");
                        default:
                            return Promise.reject(displayError(res));
                    }
                }
            }

            this.UpdateTutor = async function (id, update) {
                try {
                    const formattedPayload = convertCMSKeyToBackendKey(update, TUTOR_MODEL);
                    const payload = {
                        id: id,
                        ...formattedPayload
                    }

                    const data = await Vue.prototype.$XHR.api('edit_staff', payload);
                    const formattedData = parseTutorData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: UpdateTutor');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: UpdateTutor');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateTutor');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
             * 導師管理 List
             * ===== Optional =====
             * @param {Array<id>} center
             * @param {number} filter_page - start from 0
             * @param {number} filter_limit
             */
            this.GetTutors = async function (filters = {}) {
                try {
                    const data = await Vue.prototype.$XHR.api('get_staff', filters);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        const formattedData = data['data'].map(parseTutorData);
                        const returnedData = {
                            data: formattedData,
                            total: data.total,
                        };
                        devLog('----------> XHR [SUCCESS]: GetTutors');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetTutors');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetTutors');
                    devLog(res);
                    return Promise.reject([])
                }
            }

            this.GetTutorsByIdList = async function (id_list, filters = {}) {
                const payload = {
                    data_type: 'staff_data',
                    id_list: id_list,
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id_list', payload);
                    if (DataValid(data)) {
                        const formattedData = data.map(parseTutorData);
                        const returnedData = {
                            data: formattedData,
                            total: formattedData.length,
                        };
                        devLog('----------> XHR [SUCCESS]: GetTutorsByIdList');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetTutorsByIdList');
                        return Promise.resolve({ data: [], total: 0 });
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetTutorsByIdList');
                    devLog(res);
                    return Promise.resolve({ data: [], total: 0 });
                }
            }

            this.GetTutorById = async function (id) {
                const payload = {
                    data_type: 'staff_data',
                    id: id
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id', payload);
                    const formattedData = parseTutorData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: GetTutorById');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetTutorById');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetTutorById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.DeleteTutor = async function (id) {
                try {
                    await Vue.prototype.$XHR.api('delete_staff', { id });
                    devLog('----------> XHR [SUCCESS]: DeleteTutor');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: DeleteTutor');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.TutorChangePassword = async function (id, newPassword) {
                const payload = {
                    id: id,
                    password_data: newPassword
                }

                try {
                    await Vue.prototype.$XHR.api('edit_staff', payload);
                    devLog('----------> XHR [SUCCESS]: TutorChangePassword');
                    return Promise.resolve(true);

                } catch (res) {
                    devLog('----------> XHR [FAIL]: TutorChangePassword');
                    devLog(res);
                    return  Promise.reject(displayError(res));
                }
            }





            /**
             * 新增分校
             * ==== Required ====
             * @param {String} name 
             * @param {String} address
             * ===== Optional =====
             * @param {String} logo
             */
            this.NewSchool = async function (payload) {
                if (Object.prototype.hasOwnProperty.call(payload, 'active')) {
                    delete payload['active'];
                }

                try {
                    const formattedPayload = convertCMSKeyToBackendKey(payload, SCHOOL_MODEL);
                    const uploaded = {
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('new_center', uploaded);
                    const formattedData = parseSchoolData(data);
                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: NewSchool');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: NewSchool');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewSchool');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpdateSchool = async function (id, update) {
                try {
                    const formattedPayload = convertCMSKeyToBackendKey(update, SCHOOL_MODEL);
                    const payload = {
                        id: id,
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('edit_center', payload);
                    const formattedData = parseSchoolData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: UpdateSchool');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: UpdateSchool');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateSchool');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
             * 分校管理 List
             * ===== Optional =====
             * @param {number} filter_page - start from 0
             * @param {number} filter_limit
             * @param {number} center_id
             */
            this.GetSchools = async function (filters = {}) {
                const payload = {
                    data_type: 'center_data',
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_all_data_by_datatype', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        const formattedData = data['data'].map(parseSchoolData);
                        const returnedData = {
                            data: formattedData,
                            total: data.total,
                        };
                        devLog('----------> XHR [SUCCESS]: GetSchools');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetSchools');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetSchools');
                    devLog(res);
                    return Promise.reject([])
                }
            }

            this.GetSchoolsByIdList = async function (id_list, filters = {}) {
                const payload = {
                    data_type: 'center_data',
                    id_list: id_list,
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id_list', payload);
                    if (DataValid(data)) {
                        const formattedData = data.map(parseSchoolData);
                        const returnedData = {
                            data: formattedData,
                            total: formattedData.length,
                        };
                        devLog('----------> XHR [SUCCESS]: GetSchoolsByIdList');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetSchoolsByIdList');
                        return Promise.resolve({ data: [], total: 0 });
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetSchoolsByIdList');
                    devLog(res);
                    return Promise.resolve({ data: [], total: 0 });
                }
            }

            this.GetSchoolById = async function (id) {
                const payload = {
                    data_type: 'center_data',
                    id: id
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id', payload);
                    const formattedData = parseSchoolData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: GetSchoolById');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetSchoolById');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetSchoolById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.DeleteSchool = async function (id) {
                try {
                    await Vue.prototype.$XHR.api('delete_center', { id });

                    devLog('----------> XHR [SUCCESS]: DeleteSchool');
                    return Promise.resolve(true);

                } catch (res) {
                    devLog('----------> XHR [FAIL]: DeleteSchool');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }





            /**
             * 學生管理
             * ======= Optional ======
             * @param {String} search - search keyword
             */
            /**
             * 學生管理
             * ===== Optional =====
             * @param {number} filter_page - start from 0
             * @param {number} filter_limit
             * @param {number} center_id
             * @param {String} search - search keyword
             * @param {Array<{key: string, value: any}>} filter_item
             * filter_item key:
             * - 'name': string
             * - 'id': number
             * - 'phone': string
             * - 'grade': string
             * - 'school': string
             * - 'level': string
             * - 'create_date': string
             */
            this.GetStudents = async function (filters = {}) {
                const payload = {
                    data_type: 'student_data',
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_all_data_by_datatype', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        const formattedData = data['data'].map(parseStudentData);
                        const returnedData = {
                            data: formattedData,
                            total: data.total,
                        };
                        devLog('----------> XHR [SUCCESS]: GetStudents');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetStudents');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetStudents');
                    devLog(res);
                    return Promise.reject([])
                }
            }

            this.GetStudentsByIdList = async function (id_list, filters = {}) {
                const payload = {
                    data_type: 'student_data',
                    id_list: id_list,
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id_list', payload);
                    if (DataValid(data)) {
                        const formattedData = data.map(parseStudentData);
                        const returnedData = {
                            data: formattedData,
                            total: formattedData.length,
                        };
                        devLog('----------> XHR [SUCCESS]: GetStudentsByIdList');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetStudentsByIdList');
                        return Promise.resolve({ data: [], total: 0 });
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetStudentsByIdList');
                    devLog(res);
                    return Promise.resolve({ data: [], total: 0 });
                }
            }

            /**
             * 新增學生
             * ===== Required =====
             * @param {String} password_data
             * @param {String} username
             * 
             */
            this.NewStudent = async function (payload) {
                if (Object.prototype.hasOwnProperty.call(payload, 'active')) {
                    delete payload['active'];
                }

                try {
                    const formattedPayload = convertCMSKeyToBackendKey(payload, STUDENT_MODEL);
                    const uploaded = {
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('new_student', uploaded);

                    const formattedData = parseStudentData(data);
                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: NewStudent');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: NewStudent');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewStudent');
                    devLog(res);
                    switch (res.data) {
                        case "username exist":
                            return Promise.reject("帳戶已存在");
                        default:
                            return Promise.reject(displayError(res));
                    }
                }
            }

            this.UpdateStudent = async function (id, update) {
                try {
                    const formattedPayload = convertCMSKeyToBackendKey(update, STUDENT_MODEL);
                    const payload = {
                        id: id,
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('edit_student', payload);
                    const formattedData = parseStudentData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: UpdateStudent');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: UpdateStudent');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateStudent');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetStudentById = async function (id) {
                const payload = {
                    data_type: 'student_data',
                    id: id
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id', payload);
                    const formattedData = parseStudentData(data);
                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: GetStudentById');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetStudentById');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetStudentById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.StudentQuit = async function (id) {
                const payload = {
                    id: id,
                    status: 'quit',
                }

                try {
                    const data = await Vue.prototype.$XHR.api('edit_student', payload);
                    const formattedData = parseStudentData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: StudentQuit');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: StudentQuit');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: StudentQuit');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.StudentRemission = async function (id) {
                const payload = {
                    id: id,
                    status: 'active',
                }

                try {
                    const data = await Vue.prototype.$XHR.api('edit_student', payload);
                    const formattedData = parseStudentData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: StudentRemission');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: StudentRemission');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: StudentRemission');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.StudentChangePassword = async function (id, newPassword) {
                const payload = {
                    id: id,
                    password_data: newPassword
                }

                try {
                    await Vue.prototype.$XHR.api('edit_student', payload);
                    devLog('----------> XHR [SUCCESS]: StudentChangePassword');
                    return Promise.resolve(true);

                } catch (res) {
                    devLog('----------> XHR [FAIL]: StudentChangePassword');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpgradeStudents = async function () {
                try {
                    await Vue.prototype.$XHR.api('upgrade_student', {});
                    devLog('----------> XHR [SUCCESS]: UpgradeStudents');
                    return Promise.resolve(true);

                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpgradeStudents');
                    devLog(res);
                    switch (res.data) {
                        case 'already upgrade in 30day':
                            return Promise.reject('不可在30天內再次升級');
                        default:
                            return Promise.reject(displayError(res));
                    }
                }
            }

            /**
             * 學業成績
             * ====== required =====
             * @param {number} student_id
             */
            this.NewStudentScore = async function (payload) {
                if (Object.prototype.hasOwnProperty.call(payload, 'active')) {
                    delete payload['active'];
                }

                try {
                    const formattedPayload = convertCMSKeyToBackendKey(payload, STUDENT_SCORE_MODEL);
                    const uploaded = {
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('new_score', uploaded);
                    const formattedData = parseStudentScoreData(data);
                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: NewStudentScore');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: NewStudentScore');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewStudentScore');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpdateStudentScore = async function (id, update) {
                try {
                    const formattedPayload = convertCMSKeyToBackendKey(update, STUDENT_SCORE_MODEL);
                    const payload = {
                        id: id,
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('edit_score', payload);
                    const formattedData = parseStudentScoreData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: UpdateStudentScore');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: UpdateStudentScore');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateStudentScore');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.DeleteStudentScore = async function (id) {
                try {
                    await Vue.prototype.$XHR.api('delete_score', { id });

                    devLog('----------> XHR [SUCCESS]: DeleteStudentScore');
                    return Promise.resolve(true);

                } catch (res) {
                    devLog('----------> XHR [FAIL]: DeleteStudentScore');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
             * ===== Optional =====
             * @param {number} filter_page - start from 0
             * @param {number} filter_limit
             * @param {number} center_id
             * @param {Array<{key: string, value: any}>} filter_item
             * filter_item key:
             * - 'student_id': number
             */
            this.GetStudentScores = async function (filters) {
                const payload = {
                    data_type: 'score_data',
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_all_data_by_datatype', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        const formattedData = data['data'].map(parseStudentScoreData);
                        const returnedData = {
                            data: formattedData,
                            total: data.total,
                        };
                        devLog('----------> XHR [SUCCESS]: GetStudentScores');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetStudentScores');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetStudentScores');
                    devLog(res);
                    return Promise.reject([])
                }
            }

            /**
             * ===== Optional =====
             * @param {number} filter_page - start from 0
             * @param {number} filter_limit
             * @param {Array<{key: string, value: any}>} filter_item
             * filter_item key:
             * - 'date': string
             */
            this.GetStudentAttendances = async function (student_id, filters) {
                let filterItems = [];
                if (filters['filter_item']) {
                    filterItems = [...filters['filter_item']]
                    delete filters['filter_item'];
                }
                filterItems.push({ key: 'student_id', value: student_id });

                // --- disabled future
                // const today = new Date();
                // const todayStr = `${today.getFullYear()}-${(today.getMonth() + 1) < 10 ? '0' : ''}${(today.getMonth() + 1)}-${today.getDate() < 10 ? '0' : ''}${today.getDate()}`
                // filterItems.push({ key: 'end_date', value: todayStr });

                const payload = {
                    data_type: 'lesson_data',
                    filter_item: filterItems,
                    order_by: 'date',
                    sort_dir: 'desc',
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_all_data_by_datatype', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        const formattedData = data['data'].map(el => parseStudentAttendanceData(el, student_id));
                        const returnedData = {
                            data: formattedData,
                            total: data.total,
                        };
                        devLog('----------> XHR [SUCCESS]: GetStudentAttendances');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetStudentAttendances');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetStudentAttendances');
                    devLog(res);
                    return Promise.reject([])
                }
            }







            /**
             * 新增課室
             * ==== Required ====
             * @param {String} name 
             * @param {Number} center - center id
             */
            this.NewClassroom = async function (payload) {
                if (Object.prototype.hasOwnProperty.call(payload, 'active')) {
                    delete payload['active'];
                }

                try {
                    const formattedPayload = convertCMSKeyToBackendKey(payload, CLASSROOM_MODEL);
                    const uploaded = {
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('new_facility', uploaded);
                    const formattedData = parseClassroomData(data);
                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: NewClassroom');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: NewClassroom');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewClassroom');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpdateClassroom = async function (id, update) {
                try {
                    const formattedPayload = convertCMSKeyToBackendKey(update, CLASSROOM_MODEL);
                    const payload = {
                        id: id,
                        ...formattedPayload
                    }

                    const data = await Vue.prototype.$XHR.api('edit_facility', payload);
                    const formattedData = parseClassroomData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: UpdateClassroom');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: UpdateClassroom');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateClassroom');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
             * 課室管理 List
             * ===== Optional =====
             * @param {number} filter_page - start from 0
             * @param {number} filter_limit
             * @param {number} center_id
             */
            this.GetClassrooms = async function (filters = {}) {
                let joinSchoolData = false;
                if (filters['join_school_data'] === true) {
                    joinSchoolData = true;
                    delete filters['join_school_data'];
                }

                const payload = {
                    data_type: 'facility_data',
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_all_data_by_datatype', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        let schoolData = [];

                        if (joinSchoolData === true) {
                            const idList = data['data'].map(el => el.center_id);
                            if (idList.length > 0) {
                                const res = await this.GetSchoolsByIdList(idList);
                                if (DataValid(res['data'])) {
                                    schoolData = res['data'];
                                }
                            }
                        }

                        const formattedData = data['data'].map(el => parseClassroomData(el, schoolData));
                        const returnedData = {
                            data: formattedData,
                            total: data.total,
                        };
                        devLog('----------> XHR [SUCCESS]: GetClassrooms');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetClassrooms');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetClassrooms');
                    devLog(res);
                    return Promise.reject([]);
                }
            }

            this.GetClassroomById = async function (id) {
                const payload = {
                    data_type: 'facility_data',
                    id: id
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id', payload);
                    const formattedData = parseClassroomData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: GetClassroomById');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetClassroomById');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetClassroomById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.DeleteClassroom = async function (id) {
                try {
                    await Vue.prototype.$XHR.api('delete_facility', { id });

                    devLog('----------> XHR [SUCCESS]: DeleteClassroom');
                    return Promise.resolve(true);

                } catch (res) {
                    devLog('----------> XHR [FAIL]: DeleteClassroom');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }




            /**
             * 新增課程分類
             * ==== Required ====
             * @param {String} name 
             */
            this.NewCourseCategory = async function (payload) {
                if (Object.prototype.hasOwnProperty.call(payload, 'active')) {
                    delete payload['active'];
                }

                try {
                    const formattedPayload = convertCMSKeyToBackendKey(payload, COURSE_CATEGORY_MODEL);
                    const uploaded = {
                        ...formattedPayload
                    }

                    const data = await Vue.prototype.$XHR.api('new_course_category', uploaded);
                    const formattedData = parseCourseCategoryData(data);
                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: NewCourseCategory');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: NewCourseCategory');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewCourseCategory');
                    devLog(res);
                    switch (res.data) {
                        case 'category_code exist':
                            return Promise.reject("課程分類編號已存在");
                        default:
                            return Promise.reject(displayError(res));
                    }
                }
            }

            this.UpdateCourseCategory = async function (id, update) {
                try {
                    const formattedPayload = convertCMSKeyToBackendKey(update, COURSE_CATEGORY_MODEL);
                    const payload = {
                        id: id,
                        ...formattedPayload
                    }

                    const data = await Vue.prototype.$XHR.api('edit_course_category', payload);
                    const formattedData = parseCourseCategoryData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: UpdateCourseCategory');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: UpdateCourseCategory');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateCourseCategory');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetCourseCategories = async function (filters = {}) {
                let joinSchoolData = false;
                if (filters['join_school_data'] === true) {
                    joinSchoolData = true;
                    delete filters['join_school_data'];
                }

                const payload = {
                    data_type: 'course_category_data',
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_all_data_by_datatype', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        let schoolData = [];

                        if (joinSchoolData === true) {
                            const idList = [];
                            data['data'].forEach(el => {
                                if (DataValid(el.center)) {
                                    el.center.forEach(school_id => {
                                        if (!idList.includes(school_id)) {
                                            idList.push(school_id);
                                        }
                                    })
                                }
                            })
                            if (idList.length > 0) {
                                const res = await this.GetSchoolsByIdList(idList);
                                if (DataValid(res['data'])) {
                                    schoolData = res['data'];
                                }
                            }

                        }
                        const formattedData = data['data'].map(el => parseCourseCategoryData(el, schoolData));
                        const returnedData = {
                            data: formattedData,
                            total: data.total,
                        };
                        devLog('----------> XHR [SUCCESS]: GetCourseCategories');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetCourseCategories');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetCourseCategories');
                    devLog(res);
                    return Promise.reject([])
                }
            }

            this.GetCourseCategoriesByIdList = async function (id_list, filters = {}) {
                const payload = {
                    data_type: 'course_category_data',
                    id_list: id_list,
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id_list', payload);
                    if (DataValid(data)) {
                        const formattedData = data.map(parseCourseCategoryData);
                        const returnedData = {
                            data: formattedData,
                            total: formattedData.length,
                        };
                        devLog('----------> XHR [SUCCESS]: GetCourseCategoriesByIdList');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetCourseCategoriesByIdList');
                        return Promise.resolve({
                            data: [],
                            total: 0
                        });
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetCourseCategoriesByIdList');
                    devLog(res);
                    return Promise.resolve({
                        data: [],
                        total: 0
                    });
                }
            }

            this.GetCourseCategoryById = async function (id) {
                const payload = {
                    data_type: 'course_category_data',
                    id: id
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id', payload);
                    const formattedData = parseCourseCategoryData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: GetCourseCategoryById');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetCourseCategoryById');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetCourseCategoryById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.DeleteCourseCategory = async function (id) {
                try {
                    await Vue.prototype.$XHR.api('delete_course_category', { id });

                    devLog('----------> XHR [SUCCESS]: DeleteCourseCategory');
                    return Promise.resolve(true);

                } catch (res) {
                    devLog('----------> XHR [FAIL]: DeleteCourseCategory');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }




            /**
             * 新增課程
             * ==== Required ====
             * @param {String} name 
             */
            this.NewCourse = async function (payload) {
                if (Object.prototype.hasOwnProperty.call(payload, 'active')) {
                    delete payload['active'];
                }

                try {
                    const formattedPayload = convertCMSKeyToBackendKey(payload, COURSE_MODEL);
                    const uploaded = {
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('new_course', uploaded);
                    const formattedData = parseCourseData(data);
                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: NewCourse');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: NewCourse');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewCourse');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpdateCourse = async function (id, update) {
                try {
                    const formattedPayload = convertCMSKeyToBackendKey(update, COURSE_MODEL);
                    const payload = {
                        id: id,
                        ...formattedPayload
                    }

                    const data = await Vue.prototype.$XHR.api('edit_course', payload);
                    const formattedData = parseCourseData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: UpdateCourse');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: UpdateCourse');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateCourse');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetCourses = async function (filters = {}) {
                let joinSchoolData = false;
                let joinCategoryData = false;
                if (filters['join_school_data'] === true) {
                    joinSchoolData = true;
                    delete filters['join_school_data'];
                }
                if (filters['join_category_data'] === true) {
                    joinCategoryData = true;
                    delete filters['join_category_data'];
                }

                const payload = {
                    data_type: 'course_data',
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_all_data_by_datatype', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        let schoolData = [];
                        let categoryData = [];

                        if (joinSchoolData === true) {
                            const idList = [];
                            data['data'].forEach(el => {
                                if (DataValid(el.center)) {
                                    el.center.forEach(school_id => {
                                        if (!idList.includes(school_id)) {
                                            idList.push(school_id);
                                        }
                                    })
                                }
                            })
                            if (idList.length > 0) {
                                const res = await this.GetSchoolsByIdList(idList);
                                if (DataValid(res['data'])) {
                                    schoolData = res['data'];
                                }
                            }
                        }

                        if (joinCategoryData === true) {
                            const idList = data['data'].map(el => el.course_category_id);
                            if (idList.length > 0) {
                                const res = await this.GetCourseCategoriesByIdList(idList);
                                if (DataValid(res['data'])) {
                                    categoryData = res['data'];
                                }
                            }

                        }

                        const formattedData = data['data'].map(el => parseCourseData(el, schoolData, categoryData));
                        const returnedData = {
                            data: formattedData,
                            total: data.total,
                        };
                        devLog('----------> XHR [SUCCESS]: GetCourses');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetCourses');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetCourses');
                    devLog(res);
                    return Promise.reject([])
                }
            }

            this.GetCourseById = async function (id) {
                const payload = {
                    data_type: 'course_data',
                    id: id
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id', payload);
                    const formattedData = parseCourseData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: GetCourseById');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetCourseById');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetCourseById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.DeleteCourse = async function (id) {
                try {
                    await Vue.prototype.$XHR.api('delete_course', { id });

                    devLog('----------> XHR [SUCCESS]: DeleteCourse');
                    return Promise.resolve(true);

                } catch (res) {
                    devLog('----------> XHR [FAIL]: DeleteCourse');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }






            /**
             * 新增商品分類
             * ==== Required ====
             * @param {String} name 
             */
            this.NewProductCategory = async function (payload) {
                if (Object.prototype.hasOwnProperty.call(payload, 'active')) {
                    delete payload['active'];
                }

                try {
                    const formattedPayload = convertCMSKeyToBackendKey(payload, PRODUCT_CATEGORY_MODEL);
                    const uploaded = {
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('new_category', uploaded);
                    const formattedData = parseProductCategoryData(data);
                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: NewProductCategory');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: NewProductCategory');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewProductCategory');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpdateProductCategory = async function (id, update) {
                try {
                    const formattedPayload = convertCMSKeyToBackendKey(update, PRODUCT_CATEGORY_MODEL);
                    const payload = {
                        id: id,
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('edit_category', payload);
                    const formattedData = parseProductCategoryData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: UpdateProductCategory');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: UpdateProductCategory');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateProductCategory');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetProductCategories = async function (filters = {}) {
                const payload = {
                    data_type: 'category_data',
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_all_data_by_datatype', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        const formattedData = data['data'].map(parseProductCategoryData);
                        const returnedData = {
                            data: formattedData,
                            total: data.total,
                        };
                        devLog('----------> XHR [SUCCESS]: GetProductCategories');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetProductCategories');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetProductCategories');
                    devLog(res);
                    return Promise.reject([])
                }
            }

            this.GetProductCategoriesByIdList = async function (id_list, filters = {}) {
                const payload = {
                    data_type: 'category_data',
                    id_list: id_list,
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id_list', payload);
                    if (DataValid(data)) {
                        const formattedData = data.map(parseProductCategoryData);
                        const returnedData = {
                            data: formattedData,
                            total: formattedData.length,
                        };
                        devLog('----------> XHR [SUCCESS]: GetProductCategoriesByIdList');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetProductCategoriesByIdList');
                        return Promise.resolve({
                            data: [],
                            total: 0
                        });
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetProductCategoriesByIdList');
                    devLog(res);
                    return Promise.resolve({
                        data: [],
                        total: 0
                    });
                }
            }

            this.GetProductCategoryById = async function (id) {
                const payload = {
                    data_type: 'category_data',
                    id: id
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id', payload);
                    const formattedData = parseProductCategoryData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: GetProductCategoryById');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetProductCategoryById');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetProductCategoryById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.DeleteProductCategory = async function (id) {
                try {
                    await Vue.prototype.$XHR.api('delete_category', { id });

                    devLog('----------> XHR [SUCCESS]: DeleteProductCategory');
                    return Promise.resolve(true);

                } catch (res) {
                    devLog('----------> XHR [FAIL]: DeleteProductCategory');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }




            /**
             * 新增商品
             * ==== Required ====
             * @param {String} name 
             */
            this.NewProduct = async function (payload) {
                if (Object.prototype.hasOwnProperty.call(payload, 'active')) {
                    delete payload['active'];
                }

                try {
                    const formattedPayload = convertCMSKeyToBackendKey(payload, PRODUCT_MODEL);
                    const uploaded = {
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('new_product', uploaded);
                    const formattedData = parseProductData(data);
                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: NewProduct');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: NewProduct');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewProduct');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpdateProduct = async function (id, update) {
                try {
                    const formattedPayload = convertCMSKeyToBackendKey(update, PRODUCT_MODEL);
                    const payload = {
                        id: id,
                        ...formattedPayload
                    }

                    const data = await Vue.prototype.$XHR.api('edit_product', payload);
                    const formattedData = parseProductData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: UpdateProduct');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: UpdateProduct');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateProduct');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
             * ===== Optional =====
             * @param {number} filter_page - start from 0
             * @param {number} filter_limit
             * @param {number} center_id
             * @param {Array<{key: string, value: any}>} filter_item
             * filter_item key:
             * - 'name': string
             */
            this.GetProducts = async function (filters = {}) {
                let joinCategoryData = false;

                if (filters['join_category_data'] === true) {
                    joinCategoryData = true;
                    delete filters['join_category_data'];
                }

                const payload = {
                    data_type: 'product_data',
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_all_data_by_datatype', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        let categoryData = [];

                        if (joinCategoryData === true) {
                            const idList = data['data'].map(el => el.category);
                            if (idList.length > 0) {
                                const res = await this.GetProductCategoriesByIdList(idList);
                                if (DataValid(res['data'])) {
                                    categoryData = res['data'];
                                }
                            }

                        }

                        const formattedData = data['data'].map(el => parseProductData(el, categoryData));
                        const returnedData = {
                            data: formattedData,
                            total: data.total,
                        };
                        devLog('----------> XHR [SUCCESS]: GetProducts');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetProducts');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetProducts');
                    devLog(res);
                    return Promise.reject([])
                }
            }

            this.GetProductById = async function (id) {
                const payload = {
                    data_type: 'product_data',
                    id: id
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id', payload);
                    const formattedData = parseProductData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: GetProductById');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetProductById');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetProductById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.DeleteProduct = async function (id) {
                try {
                    await Vue.prototype.$XHR.api('delete_product', { id });

                    devLog('----------> XHR [SUCCESS]: DeleteProduct');
                    return Promise.resolve(true);

                } catch (res) {
                    devLog('----------> XHR [FAIL]: DeleteProduct');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetProductSale = async function (id) {
                try {
                    const data = await Vue.prototype.$XHR.api('get_product_sales', { product_id: id });
                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: GetProductSale');
                        devLog(data)
                        return Promise.resolve(data.length);
                    } else {
                        devLog('----------> XHR [NULL]: GetProductSale');
                        return Promise.resolve(0);
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetProductSale');
                    devLog(res);
                    return Promise.resolve(0);
                }
            }





            /**
             * 新增課程時間表 (New Lesson)
             * Single
             */
            this.NewSingleDateLesson = async function (payload) {
                if (Object.prototype.hasOwnProperty.call(payload, 'active')) {
                    delete payload['active'];
                }

                try {
                    const formattedPayload = convertCMSKeyToBackendKey(payload, CLASS_MODEL);
                    const uploaded = {
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('new_lesson', uploaded);
                    const formattedData = parseLessonData(data);
                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: NewSingleDateLesson');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: NewSingleDateLesson');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewSingleDateLesson');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
            * 新增課程時間表 (New Lesson)
            * Regular
            */
            this.NewRegularLesson = async function (payload) {
                if (Object.prototype.hasOwnProperty.call(payload, 'active')) {
                    delete payload['active'];
                }

                try {
                    const formattedPayload = convertCMSKeyToBackendKey(payload, CLASS_MODEL);
                    const uploaded = {
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('new_regular', uploaded);
                    const formattedData = paresRegularSetting(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: NewRegularLesson');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: NewRegularLesson');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewRegularLesson');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
             * ===== Optional =====
             * @param {number} filter_page - start from 0
             * @param {number} filter_limit
             * @param {number} center_id
             * @param {Array<{key: string, value: any}>} filter_item
             * filter_item key:
             * - 'date': string
             * - 'start_date': string
             * - 'end_date': string
             * - 'staff_id': number
             * - 'course_id': number
             * - 'course_id_list': Array<number>
             * - 'student_id': number
             * - 'course_code': string
             * - 'weekdays': Array<number>
             */
            this.GetLessons = async function (filters = {}) {
                let joinTutorData = false;
                if (filters['join_tutor_data'] === true) {
                    joinTutorData = true;
                    delete filters['join_tutor_data'];
                }

                let joinStudentData = false;
                if (filters['join_student_data'] === true) {
                    joinStudentData = true;
                    delete filters['join_student_data'];
                }

                const payload = {
                    data_type: 'lesson_data',
                    order_by: 'date',
                    sort_dir: 'asc',
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_all_data_by_datatype', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        let tutorData = [];
                        if (joinTutorData === true) {
                            const idList = data['data'].map(el => el.staff_id);
                            if (idList.length > 0) {
                                const res = await this.GetTutorsByIdList(idList);
                                if (DataValid(res['data'])) {
                                    tutorData = res['data'];
                                }
                            }

                        }

                        let studentData = [];
                        if (joinStudentData === true) {
                            const idList = [];
                            data['data'].forEach(el => {
                                if (DataValid(el.students)) {
                                    el.students.forEach(student_id => {
                                        idList.push(student_id);
                                    })
                                }
                            })

                            if (idList.length > 0) {
                                const res = await this.GetStudentsByIdList(idList);
                                if (DataValid(res['data'])) {
                                    studentData = res['data'];
                                }
                            }
                        }

                        const formattedData = data['data'].map(el => parseLessonData(el, tutorData, studentData));
                        const returnedData = {
                            data: formattedData,
                            total: data.total,
                        };
                        devLog('----------> XHR [SUCCESS]: GetLessons');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetLessons');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetLessons');
                    devLog(res);
                    return Promise.reject([])
                }
            }

            this.GetLessonsByIdList = async function (id_list, filters = {}) {
                const payload = {
                    data_type: 'lesson_data',
                    id_list: id_list,
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id_list', payload);
                    if (DataValid(data)) {
                        const formattedData = data.map(parseLessonData);
                        const returnedData = {
                            data: formattedData,
                            total: formattedData.length,
                        };
                        devLog('----------> XHR [SUCCESS]: GetLessonsByIdList');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetLessonsByIdList');
                        return Promise.resolve({ data: [], total: 0 });
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetLessonsByIdList');
                    devLog(res);
                    return Promise.resolve({ data: [], total: 0 });
                }
            }

            this.GetLessonById = async function (id, filters = {}) {
                let joinStudentData = false;
                if (filters['join_student_data'] === true) {
                    joinStudentData = true;
                    delete filters['join_student_data'];
                }

                let joinAttendanceData = false;
                if (filters['join_attendance_data'] === true) {
                    joinAttendanceData = true;
                    delete filters['join_attendance_data'];
                }

                const payload = {
                    data_type: 'lesson_data',
                    id: id
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id', payload);
                    let studentData = [];
                    if (joinStudentData === true && DataValid(data.students) && data.students.length > 0) {
                        const res = await this.GetStudentsByIdList(data.students);
                        if (DataValid(res['data'])) {
                            studentData = res['data'];
                        }
                    }

                    let lessonData = [];
                    if (joinAttendanceData === true && DataValid(data.attendance) && data.attendance.length > 0) {
                        const lessonIds = [];
                        data.attendance.forEach(el => {
                            if (el.from_lesson !== undefined && el.from_lesson !== null && el.from_lesson > -1) {
                                lessonIds.push(el.from_lesson);
                            }
                        })
                        if (lessonIds.length > 0) {
                            const res = await this.GetLessonsByIdList(lessonIds);
                            if (DataValid(res['data'])) {
                                lessonData = res['data'];
                            }
                        }
                    }

                    const formattedData = parseLessonData(data, [], studentData, lessonData);
                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: GetLessonById');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetLessonById');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetLessonById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpdateLesson = async function (id, update) {
                try {
                    const formattedPayload = convertCMSKeyToBackendKey(update, LESSON_MODEL);
                    const payload = {
                        id: id,
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('edit_lesson', payload);
                    const formattedData = parseLessonData(data);

                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: UpdateLesson');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: UpdateLesson');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateLesson');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.DeleteLesson = async function (id) {
                try {
                    await Vue.prototype.$XHR.api('delete_lesson', { id });

                    devLog('----------> XHR [SUCCESS]: DeleteClassroom');
                    return Promise.resolve(true);

                } catch (res) {
                    devLog('----------> XHR [FAIL]: DeleteClassroom');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }




            /**
             * 繳交學費
             */
            this.GetPayTuitions = async function (filters = {}) {
                const payload = {
                    data_type: 'student_data',
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_all_data_by_datatype', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        const formattedData = data['data'].map(parsePayTuitionData);
                        const returnedData = {
                            data: formattedData,
                            total: data.total,
                        };
                        devLog('----------> XHR [SUCCESS]: GetPayTuitions');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetPayTuitions');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetPayTuitions');
                    devLog(res);
                    return Promise.reject([])
                }
            }

            this.GetPayTuitionById = async function (id) {
                const payload = {
                    data_type: 'student_data',
                    id: id
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_data_by_id', payload);
                    const formattedData = parsePayTuitionData(data);
                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: GetPayTuitionById');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetPayTuitionById');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetPayTuitionById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }





            /**
             * 繳交學費
             * ==== Required ====
             * discount_type: 'percentage' | 'fix_amount'
             * if discount_type = 'percentage' --> Math.ceil(price * (discount_value/100) * 10) / 10
             * @param {Array<{id: -1, discount_type: '', discount_value: 0}>} lesson_id_list
             * @param {Array<{id: -1, discount_type: '', discount_value: 0, amount: 0 (qty)}>} product_id_list
             * @param {String} payment_method - 'cash'
             * @param {Number} subtotal
             * @param {String} remark
             * @param {Number} center_id
             * @param {Number} student_id
             * 
             */
            this.NewOrder = async function (payload) {
                if (Object.prototype.hasOwnProperty.call(payload, 'active')) {
                    delete payload['active'];
                }

                try {
                    const formattedPayload = convertCMSKeyToBackendKey(payload, ORDER_MODEL);
                    const uploaded = {
                        ...formattedPayload
                    }
                    const data = await Vue.prototype.$XHR.api('new_order', uploaded);
                    const formattedData = parseOrderData(data);
                    if (DataValid(formattedData)) {
                        devLog('----------> XHR [SUCCESS]: NewOrder');
                        devLog(formattedData)
                        return Promise.resolve(formattedData);
                    } else {
                        devLog('----------> XHR [NULL]: NewOrder');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }

                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewOrder');
                    devLog(res);
                    switch (res.data) {
                        case 'lesson get fail':
                        case 'lesson not found':
                            return Promise.reject("找不到課程");
                        case 'lesson get fail':
                        case 'lesson not found':
                            return Promise.reject("找不到商品");
                        case 'lesson full':
                            return Promise.reject("已滿額");
                        case 'product out of inventory':
                            return Promise.reject("暫無庫存");
                        default:
                            return Promise.reject(displayError(res));
                    }

                }
            }

            /**
             * 近期帳單
             * ===== Optional =====
             * @param {number} filter_page - start from 0
             * @param {number} filter_limit
             * @param {number} center_id
             * @param {Array<{key: string, value: any}>} filter_item
             * filter_item key:
             * - 'create_date': string
             * - 'id': number
             * - 'student_name': string
             * - 'phone': string
             */
            this.GetOrders = async function (filters = {}) {
                const payload = {
                    data_type: 'order_data',
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_all_data_by_datatype', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        const formattedData = data['data'].map(parseOrderData);
                        const returnedData = {
                            data: formattedData,
                            total: data.total,
                        };
                        devLog('----------> XHR [SUCCESS]: GetOrders');
                        devLog(returnedData)
                        return Promise.resolve(returnedData);
                    } else {
                        devLog('----------> XHR [NULL]: GetOrders');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetOrders');
                    devLog(res);
                    return Promise.reject([])
                }
            }

            this.ExportOrderInvoice = async function (order_id) {
                const payload = {
                    id: order_id,
                };

                try {
                    const data = await Vue.prototype.$XHR.api('export_pdf', payload);
                    devLog('----------> XHR [SUCCESS]: ExportOrderInvoice');
                    devLog(data);
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: ExportOrderInvoice');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }






            /**
             * 轉堂(單一課堂)
             * @param {Number} student_id 
             * @param {Number} from_id - lesson id
             * @param {Number} to_id - lesson id
             */
            this.SwitchLesson = async function (student_id, from_id, to_id) {
                const payload = {
                    student_id,
                    from_id,
                    to_id
                };

                try {
                    await Vue.prototype.$XHR.api('switch_lesson', payload);
                    devLog('----------> XHR [SUCCESS]: SwitchLesson');
                    return Promise.resolve('success');
                } catch (res) {
                    devLog('----------> XHR [FAIL]: SwitchLesson');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
            * 轉堂(揀一堂後，將未上果N堂都轉去依個course到)
            * @param {Number} student_id 
            * @param {Number} from_course_code - course id
            * @param {Number} to_course_code - course id
            * @param {String} start_date - 幾時開始轉
            */
            this.SwitchCourse = async function (student_id, from_course_code, to_course_code, start_date) {
                const payload = {
                    id: student_id,
                    from_course_code: from_course_code,
                    to_course_code: to_course_code,
                    start_date: start_date
                };

                try {
                    await Vue.prototype.$XHR.api('switch_course', payload);
                    devLog('----------> XHR [SUCCESS]: SwitchCourse');
                    return Promise.resolve('success');
                } catch (res) {
                    devLog('----------> XHR [FAIL]: SwitchCourse');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            /**
            * 退課
            * @param {Number} student_id 
            * @param {Number} from_course_code - course id
            * @param {String} start_date - 幾時開始轉
            */
            this.DropCourse = async function (student_id, from_course_code, start_date) {
                const payload = {
                    id: student_id,
                    from_course_code: from_course_code,
                    start_date: start_date
                };

                try {
                    await Vue.prototype.$XHR.api('drop_course', payload);
                    devLog('----------> XHR [SUCCESS]: DropCourse');
                    return Promise.resolve('success');
                } catch (res) {
                    devLog('----------> XHR [FAIL]: DropCourse');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

        })()
    }
}